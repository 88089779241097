import { z } from 'zod';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type';
import { NestedPositionProductSchema } from './get-position-product.ts';

export const PatchPositionGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
    productGroupId: z.string().uuid(),
});

export const PatchPositionGroupRequestSchema = z.object({
    name: z.string(),
});

export const PatchPositionGroupResponseSchema = z.strictObject({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    products: z.array(NestedPositionProductSchema),
});
