import { z } from 'zod';
import { GetCustomerInfoResponseSchema } from '../customer-info/get-customer-info.ts';
import { NestedProductGroupResponseSchema } from '../product-group/NestedGroupResponseEntity.ts';
import { ORDER_STATUS_SCHEMA } from './order-status.ts';

export const GetOrderByNumberUrlSchema = z.strictObject({
    orderNumber: z.string(),
});

/**
 * validation schema for query params
 */
export const GetOrderByNumberQuerySchema = z.strictObject({
    // extend order with product-groups
    includeGroups: z.coerce.boolean(),
    // extend order (product-groups) with order products
    includeProducts: z.coerce.boolean(),
    // extend order (groups -> products) with bundles
    includeBundles: z.coerce.boolean(),
    // extend order (groups -> products) with quotes
    includeQuotes: z.coerce.boolean(),
});

/**
 * validation schema for response payload
 */
export const GetOrderByNumberResponseSchema = z.object({
    id: z.string().uuid(),
    humanReadableId: z.string().length(11).nullable(),
    createdAt: z.coerce.date(),
    status: ORDER_STATUS_SCHEMA,
    customerInfo: GetCustomerInfoResponseSchema.nullable(),
    productGroups: z.array(NestedProductGroupResponseSchema).optional(),
    marketCode: z.string(),
    positionId: z.string().nullable(),
});

export type GetOrderByNumberResponse = z.infer<typeof GetOrderByNumberResponseSchema>;
