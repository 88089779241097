import React, { useEffect } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import { resolveCurrentUrlEnv } from '@/shared/hooks/useGlobals.ts';
import { ENVIRONMENT_LONG_NAME } from '@schuettflix/urls';
import { AuthenticatedRoutes } from '@/routes/AuthenticatedRoutes.tsx';
import { SentryUserProvider } from '@/tools/sentry/SentryUserProvider';
import { useUserflow } from '@/shared/hooks/useUserflow';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { getUserLocaleFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { useLoggedInUser } from '@/clients/organization/useOrganization.ts';

const getOktaClientId = () => {
    const STAGE_OKTA_CLIENT_ID = '0oaamb9n4c7yld34p417';
    const PROD_OKTA_CLIENT_ID = '0oaamb81mvK2xItuM417';
    const DEV_OKTA_CLIENT_ID = '0oaamb9p51YaO48qM417';

    const env = import.meta.env.VITE_SFLX_ENVIRONMENT;

    const environmentToOktaClientId: Record<string, string> = {
        [ENVIRONMENT_LONG_NAME.PRODUCTION]: PROD_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.DEMO]: PROD_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.STAGE]: STAGE_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.SANDBOX]: STAGE_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.TRAINING]: STAGE_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.NETSUITE]: STAGE_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.DEVELOPMENT]: DEV_OKTA_CLIENT_ID,
        [ENVIRONMENT_LONG_NAME.LOCAL]: env == 'local' ? DEV_OKTA_CLIENT_ID : STAGE_OKTA_CLIENT_ID,
    };

    return environmentToOktaClientId[resolveCurrentUrlEnv()] || STAGE_OKTA_CLIENT_ID;
};

const oktaAuth = new OktaAuth({
    issuer: 'https://schuettflix.okta.com/',
    clientId: getOktaClientId(),
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email', 'groups'],
});

window.userflowToken = import.meta.env.VITE_USERFLOW_TOKEN;

export const App: React.FC = () => {
    const { i18n } = useTranslation();
    const { identify } = useUserflow();

    const { data: loggedInUserData } = useLoggedInUser();

    useEffect(() => {
        try {
            if (!loggedInUserData) return;

            const { organization, user } = loggedInUserData;

            if (!user || !organization) {
                Sentry.captureException(new Error('Failed to identify user with userflow'));
                return;
            }

            void identify(user, organization, i18n.language);
        } catch (error) {
            Sentry.captureException(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserData, i18n.language]);

    useEffect(() => {
        const setUserLocaleAsDefault = async () => {
            try {
                if (!loggedInUserData) return;

                const existingUserLocale = getUserLocaleFromLocalStorage();
                if (!existingUserLocale && loggedInUserData.user.locale) {
                    // if user locale is not set in local storage, set it to the user's locale coming from /me request
                    await i18n.changeLanguage(loggedInUserData.user.locale);
                } else if (existingUserLocale) {
                    // if user locale is already set in local storage, set it to as existing locale
                    await i18n.changeLanguage(existingUserLocale);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        };

        void setUserLocaleAsDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserData]);

    const navigate = useNavigate();

    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <SentryUserProvider />
            <AuthenticatedRoutes />
        </Security>
    );
};
