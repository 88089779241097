import { z } from 'zod';
import { NestedOrderProductResponseSchema } from '../order-product/NestedProductResponseEntity.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from './product-group-type.ts';

export const PatchProductGroupRequestSchema = z.strictObject({
    id: z.string().uuid(),
    orderId: z.optional(z.string().uuid()),
    index: z.optional(z.number()),
    name: z.optional(z.string()),
});

export const PatchProductGroupResponseSchema = z.strictObject({
    id: z.string(),
    index: z.number().optional(),
    name: z.string().optional().nullable(),
    orderId: z.string().optional(),
    templateId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    orderProducts: z.array(NestedOrderProductResponseSchema),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
});
