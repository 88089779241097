import { useSuspenseQuery } from '@tanstack/react-query';
import { getTemplateApi, getTemplateGroupsApi } from './template.ts';
import { TEMPLATE_CATEGORIES, TemplateCategory, TemplateGroup } from '@schuettflix/interfaces';

export const usePositionTemplateGroups = () => {
    return useSuspenseQuery({
        queryKey: ['templateGroups'],
        queryFn: async () => getTemplateGroupsApi(),
        refetchOnWindowFocus: false,
        select: data => {
            return getTemplateGroupsPerTemplateCategory(data, TEMPLATE_CATEGORIES.POSITION);
        },
    });
};

export const useOrderTemplateGroups = () => {
    return useSuspenseQuery({
        queryKey: ['templateGroups'],
        queryFn: async () => getTemplateGroupsApi(),
        refetchOnWindowFocus: false,
        select: data => {
            return getTemplateGroupsPerTemplateCategory(data, TEMPLATE_CATEGORIES.ORDER);
        },
    });
};

export const useTemplate = (templateId?: string | null) => {
    return useSuspenseQuery({
        queryKey: ['template', templateId],
        queryFn: async () => (templateId ? getTemplateApi(templateId) : null),
        refetchOnWindowFocus: false,
    });
};

const getTemplateGroupsPerTemplateCategory = (
    templateGroups: TemplateGroup[],
    templateCategory: TemplateCategory
): TemplateGroup[] => {
    return templateGroups.map(templateGroup => ({
        ...templateGroup,
        templates: templateGroup.templates?.filter(template => template.templateCategories.includes(templateCategory)),
    }));
};
