import {
    PatchPositionProductConfigurationRequest,
    PatchPositionProductConfigurationResponse,
    PatchPositionProductConfigurationResponseSchema,
    PatchPositionProductConfigurationUrl,
} from '@schuettflix/interfaces';
import { getPositionClient } from '@/clients/httpClients.ts';

export const patchPositionProductConfiguration = async ({
    positionProductId,
    positionProductConfiguration,
}: {
    positionProductId: PatchPositionProductConfigurationUrl['positionProductId'];
    positionProductConfiguration: PatchPositionProductConfigurationRequest;
}) => {
    return getPositionClient()
        .patch<PatchPositionProductConfigurationResponse>(
            `/v1/position-product/${positionProductId}/product-configuration`,
            positionProductConfiguration
        )
        .then(res => PatchPositionProductConfigurationResponseSchema.parse(res.data));
};
