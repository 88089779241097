import {
    CreatePositionOrderResponseSchema,
    CreatePositionOrderUrl,
    GetPositionProductGroupsResponseSchema,
    GetPositionResponseSchema,
    GetPositionSummaryResponseSchema,
    PatchPositionGroupRequestSchema,
    PatchPositionRequestSchema,
    PatchPositionResponseSchema,
    PostPositionGroupRequestSchema,
    PostPositionProductRequestSchema,
    PostPositionProductResponseSchema,
    PostPositionStartEditResponse,
    PostPositionStartEditResponseSchema,
    PostPositionSubmitResponse,
    PostPositionSubmitResponseSchema,
} from '@schuettflix/interfaces';
import { getPositionClient } from '@/clients/httpClients';
import { z } from 'zod';
import { decorateOrganizationNamesByOrganizationIds } from '@/clients/organization/Organization.ts';
import * as Sentry from '@sentry/react';

export const getPositionById = async (positionId: string) => {
    return getPositionClient()
        .get<z.input<typeof GetPositionResponseSchema>>(`/v1/position/${positionId}`)
        .then(res => GetPositionResponseSchema.parse(res.data));
};

export const patchPosition = async ({
    body,
    positionId,
}: {
    body: z.input<typeof PatchPositionRequestSchema>;
    positionId: string;
}) => {
    return getPositionClient()
        .patch<z.input<typeof PatchPositionResponseSchema>>(`/v1/position/${positionId}`, body)
        .then(res => PatchPositionResponseSchema.parse(res.data));
};

export const createPositionProductGroup = async (
    positionId: string,
    productGroup: z.infer<typeof PostPositionGroupRequestSchema>
) => {
    return getPositionClient()
        .post<z.input<typeof PostPositionGroupRequestSchema>>(`/v1/position/${positionId}/product-group`, productGroup)
        .then(res => PostPositionGroupRequestSchema.parse(res.data));
};

export const getPositionProductGroups = async (positionId: string) => {
    return getPositionClient()
        .get<z.input<typeof GetPositionProductGroupsResponseSchema>>(`/v1/position/${positionId}/product-group`)
        .then(res => GetPositionProductGroupsResponseSchema.parse(res.data));
};

export const getPositionSummaryById = async (positionId: string) => {
    try {
        const positionSummary = await getPositionClient()
            .get<z.input<typeof GetPositionSummaryResponseSchema>>(`/v1/position/${positionId}/summary`)
            .then(res => GetPositionSummaryResponseSchema.parse(res.data));

        return decorateOrganizationNamesByOrganizationIds(positionSummary.organizations);
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const updatePositionProductGroup = async (
    positionId: string,
    productGroupId: string,
    productGroup: z.infer<typeof PatchPositionGroupRequestSchema>
) => {
    return getPositionClient().patch<void>(`/v1/position/${positionId}/product-group/${productGroupId}`, productGroup);
};

export const deletePositionProductGroup = async (positionId: string, productGroupId: string) => {
    await getPositionClient().delete(`/v1/position/${positionId}/product-group/${productGroupId}`);
    return;
};

export const deletePositionProduct = async (positionId: string, productGroupId: string, productId: string) => {
    await getPositionClient().delete(`/v1/position/${positionId}/product-group/${productGroupId}/product/${productId}`);
    return;
};

export const createPositionProduct = async (
    positionId: string,
    productGroupId: string,
    product: z.infer<typeof PostPositionProductRequestSchema>
) => {
    return getPositionClient()
        .post<z.input<typeof PostPositionProductRequestSchema>>(
            `/v1/position/${positionId}/product-group/${productGroupId}/product`,
            product
        )
        .then(res => PostPositionProductResponseSchema.parse(res.data));
};

export const createPositionSubmit = async (positionId: string) => {
    return getPositionClient()
        .post<PostPositionSubmitResponse>(`/v1/position/${positionId}/submit`)
        .then(res => PostPositionSubmitResponseSchema.parse(res.data));
};

export const startEditPosition = async (positionId: string) => {
    return getPositionClient()
        .post<PostPositionStartEditResponse>(`/v1/position/${positionId}/edit`)
        .then(res => PostPositionStartEditResponseSchema.parse(res.data));
};

export const commitEditPosition = async (positionId: string, temporaryId: string) => {
    return getPositionClient().post<void>(`/v1/position/${positionId}/commit-edit/${temporaryId}`);
};

export const createPositionOrder = async (positionId: string) => {
    return getPositionClient()
        .post<CreatePositionOrderUrl>(`/v1/position/${positionId}/order`)
        .then(response => CreatePositionOrderResponseSchema.parse(response.data));
};
