import { Button } from '@schuettflix/planum-react';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';
import { useTranslation } from 'react-i18next';
import { useCommitEditPosition } from '@/clients/position/useCommitEditPosition.ts';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { usePosition } from '@/clients/position/usePosition.ts';
import { BeforeUnloadContext } from '@/shared/context/BeforeUnloadContext.tsx';
import { useContext } from 'react';

export function PositionSaveButton() {
    const { t } = useTranslation();
    const { positionId, temporaryId, isEditing } = useEditPositionState();
    const { data: position } = usePosition(positionId);
    const beforeUnloadContext = useContext(BeforeUnloadContext);

    const commitEditPositionMutation = useCommitEditPosition(positionId!, temporaryId!);

    const { openUrl } = useMonolithFrontendUrl({
        target: 'currentTab',
        historyMethod: 'push',
    });

    if (!position || !isEditing) {
        return;
    }

    const handleClick = async () => {
        beforeUnloadContext.setEnabled(false);

        await commitEditPositionMutation.mutateAsync();

        openUrl({
            path: `/order-management/projects/${position.customerInfo?.constructionProjectId}/generic-positions/${position.id}`,
            toast: {
                type: 'success',
                message: t('positions.successfully-updated', {
                    positionNumber: position?.positionNumber,
                }),
            },
        });
    };

    return (
        <Button
            onPress={handleClick}
            isLoading={commitEditPositionMutation.isPending || commitEditPositionMutation.isSuccess}
        >
            {t('position.summary.savePosition')}
        </Button>
    );
}
