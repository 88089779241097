import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { filter, FilterOptionsOrganization, getOrganizationById, getLoggedInUser } from './Organization';

export function useOrganization(id: number | undefined | null) {
    return useQuery({
        queryKey: ['organization', id!],
        queryFn: () => getOrganizationById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
    });
}

export function useOrganizationSuspenseQuery(id: number | undefined | null) {
    return useSuspenseQuery({
        queryKey: ['organization', id],
        queryFn: () => (id ? getOrganizationById(id) : null),
        refetchOnWindowFocus: false,
    });
}

export function useFilterOrganizations(filterOptions: FilterOptionsOrganization, enabled = true) {
    return useQuery({
        queryKey: ['organizations', filterOptions],
        queryFn: () => filter(filterOptions),
        refetchOnWindowFocus: false,
        enabled,
        staleTime: 60000,
    });
}

export function useOrganizationName(id: number | undefined | null) {
    return useQuery({
        queryKey: ['organization', id!],
        queryFn: () => getOrganizationById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
        select: data => data?.name,
    });
}

export function useLoggedInUser() {
    return useQuery({
        queryKey: ['loggedInUser'],
        queryFn: () => getLoggedInUser(),
        // we assume that the user will not change during the session
        staleTime: Infinity,
    });
}
