import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { BILLING_TYPE_SCHEMA } from '../finance-settings/billing-types.ts';
import { BILLING_INTERVAL_SCHEMA } from '../finance-settings/billing-interval.ts';
import { ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA } from '../finance-settings/role.ts';
import { LINE_ITEM_TYPE_SCHEMA } from '../events/item-fulfillment-event-payload';

export const GetPaymentSettingsByOrderUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const GetPaymentSettingsByOrderResponseSchema = z.array(
    z.object({
        orgId: z.number().int(),
        transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
        category: PRODUCT_CATEGORY_SCHEMA,
        type: LINE_ITEM_TYPE_SCHEMA,
        hasProjectPosition: z.boolean(),
        billingMethod: BILLING_TYPE_SCHEMA,
        billingInterval: BILLING_INTERVAL_SCHEMA.nullish(),
        paymentTermId: z.number().int().nullable(),
    })
);
