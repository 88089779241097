export const authTokenFromLocalStorage = () => {
    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (oktaTokenStorage?.includes('idToken')) {
        return oktaTokenStorage.split('"idToken":"')[1].split('"')[0];
    }
    return '';
};

export const isAuthenticated = () =>
    !!localStorage.getItem('okta-token-storage')?.split('"idToken":"')[1]?.split('"')[0];

export const getUserLocaleFromLocalStorage = () => localStorage.getItem('locale');

export const setUserLocaleToLocalStorage = (locale: string) => localStorage.setItem('locale', locale);
