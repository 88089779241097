import z from 'zod';

export const PositionEditStartProductMappingSchema = z.strictObject({
    originalPositionProductId: z.string().uuid(),
    temporaryPositionProductId: z.string().uuid(),
});

export const PostPaymentSettingsPositionEditStartRequestSchema = z.strictObject({
    originalPositionId: z.string().uuid(),
    temporaryPositionId: z.string().uuid(),
    productMapping: z.array(PositionEditStartProductMappingSchema),
});

export type PostPaymentSettingsPositionEditStartRequest = z.infer<
    typeof PostPaymentSettingsPositionEditStartRequestSchema
>;
