import { z } from 'zod';
import { order } from '@schuettflix/schema-registry-ts';

const TranslatableKeyValue = order.shared.versions.v1.schemas.TranslatableKeyValue;
const MetadataSchema = z.array(TranslatableKeyValue);

export const ProductSubmittedEventSchema = z.object({
    productId: z.string(),
    metadata: MetadataSchema,
});

export type ProductSubmittedEventPayload = z.infer<typeof ProductSubmittedEventSchema>;
