import {
    GetProductGroupListResponseSchema,
    ListResponseBody,
    PostProductGroupResponseSchema,
    PostOrderProductResponseSchema,
    PRODUCT_GROUP_TYPE,
    PRODUCT_CATEGORY,
    PRODUCT_TYPES,
    GetProductGroupResponse,
    GetProductGroupResponseSchema,
    DeleteOrderProductUrlSchema,
    GetSupportedUnitListResponseSchema,
    PatchOrderProductConfigurationUrl,
    PatchOrderProductConfigurationResponseSchema,
    PatchOrderProductConfigurationResponse,
    PatchOrderProductConfigurationRequest,
} from '@schuettflix/interfaces';
import { getOrderClient } from '../httpClients';
import { z } from 'zod';

export async function createProductSelectionApi(
    type: PRODUCT_TYPES,
    category: PRODUCT_CATEGORY,
    productGroupId: string,
    configurationId?: string,
    index?: number,
    templateId?: string
) {
    return getOrderClient()
        .post<z.input<typeof PostOrderProductResponseSchema>>(`/v1/product-group/${productGroupId}/order-product`, {
            type,
            configurationId,
            index,
            templateId,
            category,
        })
        .then(res => PostOrderProductResponseSchema.parse(res.data));
}

export async function createProductGroupApi({
    orderId,
    productGroupName,
    index,
    templateId,
    type,
}: {
    orderId: string;
    productGroupName?: string;
    index: number;
    templateId: string;
    type: PRODUCT_GROUP_TYPE;
}) {
    return getOrderClient()
        .post<z.input<typeof PostProductGroupResponseSchema>>('/v1/product-group', {
            orderId,
            name: productGroupName,
            index,
            templateId,
            type,
        })
        .then(res => PostProductGroupResponseSchema.parse(res.data));
}

export async function deleteProductSelectionApi(orderProductId: string) {
    await getOrderClient()
        // TODO: fix all schema typings so that they are actually typed
        // TODO: fix this schema typing so it is actually typed
        .delete<z.input<typeof DeleteOrderProductUrlSchema>>(`/v1/order-product/${orderProductId}`);
}

export async function getProductGroupByIdApi(productGroupId: string) {
    return getOrderClient()
        .get<GetProductGroupResponse>(`/v1/product-group/${productGroupId}`)
        .then(res => GetProductGroupResponseSchema.parse(res.data));
}

export async function getOrderProductGroupsApi(orderId: string) {
    return getOrderClient()
        .get<ListResponseBody<z.input<typeof GetProductGroupListResponseSchema>>>('/v1/product-groups', {
            params: {
                orderId,
            },
        })
        .then(res => res.data.items.map(item => GetProductGroupListResponseSchema.parse(item)));
}

export const getSupportedUnitsByProductType = async (productType: string) => {
    return getOrderClient().get<ListResponseBody<z.infer<typeof GetSupportedUnitListResponseSchema>>>(
        `/v1/product-type/${productType}/supported-units`
    );
};

export const patchOrderProductConfiguration = async ({
    orderProductId,
    orderProductConfiguration,
}: {
    orderProductId: PatchOrderProductConfigurationUrl['orderProductId'];
    orderProductConfiguration: PatchOrderProductConfigurationRequest;
}) => {
    return getOrderClient()
        .patch<PatchOrderProductConfigurationResponse>(
            `/v1/product-configuration/${orderProductId}`,
            orderProductConfiguration
        )
        .then(response => PatchOrderProductConfigurationResponseSchema.parse(response.data));
};
