import mitt from 'mitt';
import { useEffect, useId, useRef } from 'react';

const emitter = mitt();

export type EmitterSubscriber<TPayload = void> = {
    /**
     * Subscribes a handler function to receive payloads emitted by the emitter.
     *
     * @param handler - The function that will be called with the emitted payload.
     * @returns A function to unsubscribe the handler from the emitter.
     */
    subscribe: (handler: (payload: TPayload) => void) => () => void;
};

export type EmitterEmitter<TPayload = void> = {
    /**
     * Emits a payload, triggering all subscribed handlers.
     *
     * @param payload - The payload to be emitted to the subscribers.
     */
    emit: (payload: TPayload) => void;
};

export type Emitter<TPayload> = EmitterSubscriber<TPayload> & EmitterEmitter<TPayload>;

/**
 * A custom hook that provides an event emitter for subscribing to and emitting events.
 * This is probably most useful when a parent component needs to tell a child component
 * to do something at a specific time (e.g. reset a form) and using an arbitrary changing
 * prop would be cumbersome.
 *
 * @template TPayload - The type of the payload that can be emitted. Detaults to `void` (=no payload)
 * @returns An object with `subscribe` and `emit` methods for handling events.
 *
 * @example
 * ```tsx
 * const Parent = () => {
 *     const resetEmitter = useEmitter();
 *     return (
 *         <>
 *             <button onClick={() => resetEmitter.emit()}>Reset</button>
 *             <Child resetEmitter={resetEmitter} />
 *         </>
 *     );
 * };
 *
 * const Child = ({ resetEmitter }: { resetEmitter: EmitterSubscriber }) => {
 *     const [someFormData, setSomeFormData] = useState('');
 *     useEffect(() => resetEmitter.subscribe(() => setSomeFormData('')), [resetEmitter, setSomeFormData]);
 *     return <div>{someFormData}</div>;
 * };
 * ```
 */
export const useEmitter = <TPayload = void>(): Emitter<TPayload> => {
    const id = useId();

    // Clean up the emitter when the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => emitter.off(id), []);

    // Return a ref object with the subscribe and emit functions
    return useRef({
        subscribe: (handler: (payload: TPayload) => void) => {
            emitter.on(id, handler as (payload: unknown) => void);
            return () => emitter.off(id, handler as (payload: unknown) => void);
        },
        emit: (payload: TPayload) => emitter.emit(id, payload),
    }).current;
};
