import { Outlet, useParams } from 'react-router-dom';
import { MarketContextProvider } from '@/shared/context/MarketContext.tsx';
import { usePosition } from '@/clients/position/usePosition.ts';

type PositionRouteParams = { positionId: string };

export function RouteWithPositionId() {
    const { positionId } = useParams<PositionRouteParams>();
    const { data } = usePosition(positionId);

    if (!data) {
        return;
    }

    return (
        <MarketContextProvider defaultMarketCode={data.marketCode}>
            <Outlet />
        </MarketContextProvider>
    );
}
