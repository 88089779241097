import {
    PositionCustomRequestProductManager,
    PositionCustomRequestProductManagerProps,
} from '@/CustomRequestProductChannel/modules/position/PositionCustomRequestProductManager.tsx';
import { CustomRequestProductFormProvider } from '@/CustomRequestProductChannel/components/CustomRequestProductFormProvider.tsx';

export type PositionCustomRequestProductProps = PositionCustomRequestProductManagerProps;

export function PositionCustomRequestProduct(props: PositionCustomRequestProductProps) {
    return (
        <CustomRequestProductFormProvider>
            <PositionCustomRequestProductManager {...props} />
        </CustomRequestProductFormProvider>
    );
}
