import React, { useCallback, useState } from 'react';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PresetSelection } from '@/shared/components/ProductSelection/PresetSelection/PresetSelection';
import { useCreateOrderProduct, useDeleteOrderProduct } from '@/clients/orderProducts/useOrderProduct.ts';
import {
    useDeleteProductGroup,
    useOrderProductsGroups,
    useUpdateProductGroup,
} from '@/clients/orderProducts/useOrderProductGroup.ts';
import { ProductGroups, ProductGroupsProps } from '@/shared/components/ProductSelection/ProductGroups';
import {
    ProductSelectionSummary,
    ProductSelectionSummaryRow,
} from '@/shared/components/ProductSelection/ProductSelectionSummary';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { useOrderTemplateGroups } from '@/clients/template/useTemplate.ts';
import { ProductSelection } from '@/shared/components/ProductSelection/ProductSelection.tsx';
import { OrderCustomRequestProduct } from '@/CustomRequestProductChannel/modules/order/OrderCustomRequestProduct';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses.ts';
import { mapOrderProductGroups } from '@/modules/order/components/ProductSelection/utils/mapOrderProductGroups.ts';
import { useCreateOrderProductGroupsByTemplate } from './hooks/useCreateOrderProductGroupsByTemplate';

interface OrderProductSelectionProps {
    isOpen: boolean;
    orderId: string;
    orderPositionId: string | null;
    onComplete: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

export const OrderProductSelection: React.FC<OrderProductSelectionProps> = ({
    isOpen,
    orderId,
    orderPositionId,
    onComplete,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const taxInfo = useFormattedActiveTaxClassesByOrder(orderId);
    const createOrderProductGroupsByTemplate = useCreateOrderProductGroupsByTemplate(orderId);

    const { orderProductGroups } = useOrderProductsGroups(orderId);
    const { createOrderProduct } = useCreateOrderProduct();
    const { deleteProductGroup } = useDeleteProductGroup();
    const templateGroups = useOrderTemplateGroups();

    const onDeleteProductGroup = async (productGroupId: string) => {
        await deleteProductGroup(productGroupId);
        await queryClient.invalidateQueries({ queryKey: ['order-product-group', 'get', orderId] });

        if (orderProductGroups.length === 1) {
            onDelete();
        }
    };

    const {
        deleteOrderProduct: { mutateAsync: deleteProductMutateAsync },
    } = useDeleteOrderProduct(orderId);

    const handleDeleteProductById = async (productId: string) => {
        await deleteProductMutateAsync({ orderProductId: productId });
    };

    const addProductToProductGroup = async (product: Product, productGroupId: string) => {
        const orderProducts = orderProductGroups[0].orderProducts;
        const highestExistingIndex = orderProducts?.map(product => product.index).sort((a, b) => b - a)[0] ?? 0;
        await createOrderProduct.mutateAsync({
            type: product.type,
            productGroupId,
            index: highestExistingIndex + 1,
            category: product.category,
        });
        await queryClient.invalidateQueries({ queryKey: ['order-product-group', 'get', orderId] });
    };

    const {
        updateProductGroup: { mutate: mutatePatchProductGroup },
    } = useUpdateProductGroup(orderId);

    const handleProductGroupNameChange = useCallback(
        (updatedProductGroupName: string | undefined, productGroupId: string) => {
            mutatePatchProductGroup({
                id: productGroupId,
                orderId: orderId,
                name: updatedProductGroupName,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutatePatchProductGroup]
    );

    const [completedProducts, setCompletedProducts] = useState<Set<string>>(
        () =>
            new Set([
                ...orderProductGroups.flatMap(
                    group => group.orderProducts?.filter(product => product.quote?.id).map(product => product.id) ?? []
                ),
            ])
    );

    const disableNextButton = useIsMutating({ mutationKey: ['order-product-group', 'product-quote'] }) > 0;
    const handleProductCompleteChange = useCallback((productId: string, isComplete: boolean) => {
        setCompletedProducts(prevState => {
            if (isComplete) {
                prevState.add(productId);
            } else {
                prevState.delete(productId);
            }
            return prevState;
        });
    }, []);

    const customRequestProductRenderer = useCallback<ProductGroupsProps['productRender']>(
        props => {
            return (
                <OrderCustomRequestProduct
                    {...props}
                    orderId={orderId}
                    onCompleteChange={handleProductCompleteChange}
                />
            );
        },
        [orderId, handleProductCompleteChange]
    );

    return (
        <ProductSelection
            isLoading={createOrderProduct.isPending || createOrderProductGroupsByTemplate.isPending}
            isOpen={isOpen}
            hasProductGroups={!!orderProductGroups?.length}
            summarySlot={
                <ProductSelectionSummary
                    orderProductGroups={mapOrderProductGroups(orderProductGroups)}
                    onEdit={onEdit}
                    onDelete={onDeleteProductGroup}
                    disableDelete={!!orderPositionId}
                    rowRenderer={({ productId }) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        const { data: quote } = useProductQuote(productId);
                        return <ProductSelectionSummaryRow quote={constructProductQuote({ quote, taxInfo })} />;
                    }}
                />
            }
            presetSelectionSlot={
                <PresetSelection
                    templateGroups={templateGroups?.data}
                    onSelect={createOrderProductGroupsByTemplate.mutateAsync}
                />
            }
            productGroupsSlot={
                <ProductGroups
                    onNextSection={() => {
                        onComplete();
                    }}
                    orderProductGroups={mapOrderProductGroups(orderProductGroups)}
                    onDeleteProductGroupById={onDeleteProductGroup}
                    onDeleteProductById={handleDeleteProductById}
                    onAddProductToProductGroup={addProductToProductGroup}
                    onProductGroupNameChange={handleProductGroupNameChange}
                    completedProducts={completedProducts}
                    disableNextButton={disableNextButton}
                    disableDeleteAllProducts={!!orderPositionId}
                    nextButtonLabel={t('product.productTemplates.toSummaryBtn')}
                    productRender={customRequestProductRenderer}
                />
            }
        />
    );
};
