interface SectionPreviewProps {
    headline: string;
    description: string;
}

export const SectionPreview = ({ headline, description }: SectionPreviewProps) => {
    return (
        <div className="bg-surface-disabled w-full max-w-[720px] rounded p-4">
            <h2 className="font-copy-md text-subdued mb-2" aria-disabled>
                {headline}
            </h2>
            <p className="font-copy-sm text-subdued max-w-prose">{description}</p>
        </div>
    );
};
