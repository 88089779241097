import { z } from 'zod';

export const GetSupportingDocumentsMetadataSchema = z.object({
    documentIds: z.array(z.string()),
});

export type GetSupportingDocumentsMetadata = z.infer<typeof GetSupportingDocumentsMetadataSchema>;

export const GetSupportingDocumentsMetadataResponseSchema = z.object({
    files: z.array(
        z.object({
            url: z.string(),
            fileName: z.string(),
        })
    ),
});

export type GetSupportingDocumentsMetadataResponse = z.infer<typeof GetSupportingDocumentsMetadataResponseSchema>;
