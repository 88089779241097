import { FormError, useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { useEffect, useId } from 'react';

/**
 * Custom hook that updates the errors in the fulfillment context based on the provided errors array.
 * @param errors - An array of error objects.
 */
export const useFulfillmentSectionsErrors = (errors: Omit<FormError, 'sectionId'>[]) => {
    const { setErrors } = useFulfillmentContext();
    const sectionId = useId();

    useEffect(() => {
        setErrors(prevState => {
            return [...prevState, ...errors.map(e => ({ ...e, sectionId }))];
        });

        return () => {
            setErrors(prevState => {
                return prevState.filter(error => error.sectionId !== sectionId);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(errors), setErrors]);
};
