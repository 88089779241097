import { useSuspenseQuery } from '@tanstack/react-query';
import { getFulfillmentProductGroupsByOrderId } from '@/clients/fulfillment/fulfillment.ts';

export function getFulfillmentProductGroupsByOrderIdQueryKey(orderId: string) {
    return ['fulfillment-product-groups-by-order-id', orderId];
}

export const useGetFulfillmentProductGroupByOrderId = (orderId: string) => {
    return useSuspenseQuery({
        queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(orderId),
        queryFn: () => getFulfillmentProductGroupsByOrderId(orderId),
        refetchOnWindowFocus: false,
        select: data => data[0],
    });
};
