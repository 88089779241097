import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { filter, FilterOptionsUser, getUserById, User } from './Users';

export function useFilterUsers(filterOptions: FilterOptionsUser) {
    return useQuery({
        queryKey: ['users', filterOptions],
        queryFn: () => filter(filterOptions),
    });
}

export function useUser(id?: number | null, options?: UseQueryOptions<User>) {
    return useQuery({
        queryKey: ['user', id!],
        queryFn: () => getUserById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
        ...options,
    });
}

export function useUserSuspenseQuery(id?: number | null) {
    return useSuspenseQuery({
        queryKey: ['user', id],
        queryFn: () => (id ? getUserById(id) : null),
        refetchOnWindowFocus: false,
    });
}
