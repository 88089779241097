import { QueryClient, useQuery } from '@tanstack/react-query';
import { getSellerProductsByFactory } from '@/clients/sellerProducts/SellerProduct.ts';

export function useSellerProducts(factoryId: number | null) {
    return useQuery({
        queryKey: ['factories', 'seller-products', factoryId],
        queryFn: () => (factoryId !== null ? getSellerProductsByFactory(factoryId) : null),
        enabled: !!factoryId,
        refetchOnWindowFocus: false,
    });
}

export function invalidateSellerProductsQuery(queryClient: QueryClient, factoryId: number) {
    return queryClient.invalidateQueries({ queryKey: ['factories', 'seller-products', factoryId] });
}
