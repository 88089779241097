import { AxiosError, isAxiosError } from 'axios';
import { ErrorResponseBody } from '@schuettflix/interfaces';
import { ZodError } from 'zod';
import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

function isErrorResponseWithZodError(e: unknown): e is AxiosError<ErrorResponseBody<ZodError>> {
    if (isAxiosError<ErrorResponseBody<ZodError>>(e)) {
        return e.response?.status === 400 && Array.isArray(e.response?.data?.error?.additionalInfo?.issues);
    }

    return false;
}

export function processRemoteFormViolations<T extends FieldValues>(
    setError: UseFormSetError<T>,
    e: unknown,
    pathMap: Record<string, FieldPath<T>> = {}
) {
    if (!isErrorResponseWithZodError(e)) {
        return;
    }

    const issues = e.response?.data?.error?.additionalInfo?.issues || [];

    issues.forEach(issue => {
        const path = issue.path.join('.');
        const remappedPath = pathMap[path] ?? (path as FieldPath<T>);

        setError(remappedPath, {
            message: issue.message,
        });
    });
}
