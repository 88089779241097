import { getCustomRequestClient } from '@/clients/httpClients.ts';
import { GetQuoteListByOrderIdResponsePayload, ListResponseBody } from '@schuettflix/interfaces';
import { z } from 'zod';

export async function getQuotesByOrder(orderId: string) {
    return getCustomRequestClient()
        .get<ListResponseBody<z.input<typeof GetQuoteListByOrderIdResponsePayload>>>(`/v1/quote/order/${orderId}`)
        .then(response =>
            response.data.items.map(item => {
                return GetQuoteListByOrderIdResponsePayload.parse(item).payload;
            })
        );
}
