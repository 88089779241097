import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { useEffect } from 'react';

export const useSetSectionsValidity = () => {
    const { setInvalidSections } = useFulfillmentContext();

    return (sectionName: string, isValid: boolean) => {
        setInvalidSections(prevState => {
            if (isValid) {
                return prevState.filter((section: string) => section !== sectionName);
            }

            if (prevState.find(section => section === sectionName)) {
                return prevState;
            }

            return [...prevState, sectionName];
        });
    };
};

export const useSectionsValidity = (sectionName: string, isValid: boolean) => {
    const { setInvalidSections } = useFulfillmentContext();

    useEffect(() => {
        setInvalidSections(prevState => {
            if (isValid) {
                return prevState.filter((section: string) => section !== sectionName);
            }

            if (prevState.find(section => section === sectionName)) {
                return prevState;
            }

            return [...prevState, sectionName];
        });
    }, [isValid, sectionName, setInvalidSections]);
};
