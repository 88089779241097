import {
    FilterOptionsConstructionProject,
    GetConstructionProjectListResponseSchema,
    GetConstructionProjectResponse,
    GetConstructionProjectResponseSchema,
    GetConstructionProjectsListResponse,
} from './types/constructionProject';
import { getConstructionProjectClient } from '../httpClients';

export async function getConstructionProjectListByOrganizationIdAndStatusIn(
    organizationId: number,
    filterOptions: FilterOptionsConstructionProject
) {
    return getConstructionProjectClient()
        .get<GetConstructionProjectsListResponse>(`/admin/v1/organizations/${organizationId}/construction-projects`, {
            params: filterOptions,
        })
        .then(res => GetConstructionProjectListResponseSchema.parse(res.data));
}

export async function getConstructionProjectById(id: string) {
    return getConstructionProjectClient()
        .get<GetConstructionProjectResponse>(`/admin/v1/construction-projects/${id}`)
        .then(res => GetConstructionProjectResponseSchema.parse(res.data));
}
