import { z } from 'zod';
import { GetPositionQuoteResponseSchema } from './get-position-quote.ts';

export const GetPositionQuotesRequestSchema = z.object({
    positionIds: z.array(z.string().uuid()),
});

export type GetPositionQuotesRequest = z.infer<typeof GetPositionQuotesRequestSchema>;

export const GetPositionQuotesListResponseSchema = z.object({
    items: z.array(GetPositionQuoteResponseSchema),
    total: z.number(),
});

export type GetPositionQuotesResponse = z.infer<typeof GetPositionQuotesListResponseSchema>;
