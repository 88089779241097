import { decodeToken } from '@okta/okta-auth-js';
import { MarketCode, MarketCodeSchema } from '@/shared/context/MarketContext.tsx';
import * as Sentry from '@sentry/react';

export const getMarketsFromToken = (idToken: string): MarketCode[] => {
    try {
        const jwt = decodeToken(idToken);

        if (Object.hasOwn(jwt.payload, 'groups')) {
            const invalidMarketCodes: string[] = [];

            const marketCodes = (jwt.payload.groups as string[])
                // NOTE: individual group names look like: "platform-admin-de"
                .filter(labelWithUnsafeFormat => /.*-[a-z]{2}$/i.test(labelWithUnsafeFormat))
                .map(labelWithSafeFormat => labelWithSafeFormat.split('-').pop()!)
                .map(rawMarketCode => rawMarketCode.toUpperCase())
                .filter(unsafeMarketCode => {
                    const result = MarketCodeSchema.safeParse(unsafeMarketCode);
                    if (!result.success) {
                        invalidMarketCodes.push(unsafeMarketCode);
                    }
                    return result.success;
                })
                .map(marketCodeAsString => marketCodeAsString as MarketCode);

            if (invalidMarketCodes.length > 0) {
                Sentry.captureException(`Invalid market codes detected in ID Token: ${invalidMarketCodes.join(', ')}`);
            }

            return marketCodes;
        }
    } catch {
        return [];
    }

    return [];
};
