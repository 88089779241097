import React, { useEffect } from 'react';
import { Price } from '@schuettflix/conversion';
import {
    DebouncedCustomRequestProduct,
    DebouncedCustomRequestProductProps,
    SimpleDebouncedCustomRequestProductProps,
} from '../../components/DebouncedCustomRequestProduct';
import { useFormattedActiveTaxClassesByMarket } from '@/clients/tax-classes/useTaxClasses';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePosition } from '@/clients/position/usePosition.ts';
import {
    createPositionQuote,
    patchPositionQuoteByPositionProductId,
} from '@/CustomRequestProductChannel/clients/CustomRequestPositionClient';
import { useTranslation } from 'react-i18next';
import { getPositionQuoteQueryKey, usePositionQuote } from '@/CustomRequestProductChannel/hooks/usePositionQuote.ts';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { isAxiosError } from 'axios';
import { useFormContext } from 'react-hook-form';
import { CustomRequestProductForm } from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { processRemoteFormViolations } from '@/shared/utils/formViolations.ts';
import { GetPositionQuoteResponseSchema, NestedPositionProductConfiguration } from '@schuettflix/interfaces';
import { z } from 'zod';
import { getPositionProductGroupsQueryKey } from '@/clients/position/usePositionProductGroup.ts';
import { patchPositionProductConfiguration } from '@/clients/position/position-product.ts';
import { getPatchPositionProductConfigurationQueryKey } from '@/clients/position/usePositionProduct.ts';

export type PositionCustomRequestProductManagerProps = SimpleDebouncedCustomRequestProductProps & {
    positionId: string;
};

export const PositionCustomRequestProductManager: React.FC<PositionCustomRequestProductManagerProps> = ({
    positionId,
    ...props
}) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { data: position } = usePosition(positionId);
    const taxInfo = useFormattedActiveTaxClassesByMarket(position?.marketCode);
    const platformOrganizationId = usePlatformOrganizationId();
    const orderingOrganizationId = position?.customerInfo?.organizationId;
    const { setError } = useFormContext<CustomRequestProductForm>();

    function updateQueryData(data: z.infer<typeof GetPositionQuoteResponseSchema>) {
        queryClient.setQueryData(getPositionQuoteQueryKey(props.product.id), data);
    }

    function updatePositionProductGroupData() {
        void queryClient.invalidateQueries({
            queryKey: getPositionProductGroupsQueryKey(positionId),
        });
    }

    const { data: positionQuote, isLoading } = usePositionQuote(props.product.id);

    useEffect(() => {
        if (positionQuote) {
            props.onCompleteChange(props.product.id, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionQuote]);

    const { mutateAsync: mutateAsyncPostPositionQuote } = useMutation({
        mutationKey: ['createPositionQuote'],
        mutationFn: createPositionQuote,
        onSuccess: updateQueryData,
    });

    const { mutateAsync: mutateAsyncPatchPositionQuote } = useMutation({
        mutationKey: ['patchPositionQuote', props.product.id],
        mutationFn: patchPositionQuoteByPositionProductId,
        onSuccess: updateQueryData,
    });

    const { mutateAsync: mutateAsyncPatchPositionProductConfiguration } = useMutation({
        mutationKey: getPatchPositionProductConfigurationQueryKey(props.product.id),
        mutationFn: patchPositionProductConfiguration,
        onSuccess: updatePositionProductGroupData,
    });

    const handleSubmit: DebouncedCustomRequestProductProps['onSubmit'] = async formValues => {
        try {
            if (positionQuote) {
                await mutateAsyncPatchPositionQuote({
                    positionProductId: props.product.id,
                    positionQuote: {
                        name: formValues.productName,
                        quota: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        salesTaxClassId: formValues.salesTaxClassId,
                        currencyCode: formValues.currencyCode,
                        productCategory: props.product.category,
                        productType: props.product.type,
                    },
                });
                props.onCompleteChange(props.product.id, true);
            } else if (platformOrganizationId && orderingOrganizationId) {
                await mutateAsyncPostPositionQuote({
                    positionQuote: {
                        name: formValues.productName,
                        quota: formValues.amount,
                        unit: formValues.unit,
                        partnerOrganizationId: formValues.partnerOrganizationId,
                        purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                        purchaseTaxClassId: formValues.purchaseTaxClassId,
                        salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                        salesTaxClassId: formValues.salesTaxClassId,
                        currencyCode: formValues.currencyCode,
                        productCategory: props.product.category,
                        productType: props.product.type,
                        positionProductId: props.product.id,
                        positionId,
                        projectOwnerOrganizationId: orderingOrganizationId,
                        platformOrganizationId: platformOrganizationId,
                    },
                });
                props.onCompleteChange(props.product.id, true);
            }
        } catch (e) {
            if (isAxiosError(e)) {
                processRemoteFormViolations(setError, e, {
                    quota: 'amount',
                });
            }

            props.onCompleteChange(props.product.id, false);
        }
    };

    const handleUpdatePositionProductConfiguration = async (
        positionProductConfiguration: Partial<Omit<NestedPositionProductConfiguration, 'positionProductId'>> | null
    ) => {
        if (!props.product.id || !positionProductConfiguration) {
            return;
        }

        await mutateAsyncPatchPositionProductConfiguration({
            positionProductId: props.product.id,
            positionProductConfiguration,
        });
    };

    return position?.customerInfo && !isLoading ? (
        <DebouncedCustomRequestProduct
            {...props}
            customerId={position.customerInfo.organizationId}
            quote={constructProductQuote({ positionQuote, taxInfo })}
            taxInfo={taxInfo}
            formFieldSettings={{
                ...(props.formFieldSettings ?? {}),
                serviceDate: {
                    ...(props.formFieldSettings?.serviceDate ?? {}),
                    isOptional: true,
                    isHidden: true,
                },
                amount: {
                    label: t('product.quote.quota'),
                    ...(props.formFieldSettings?.amount ?? {}),
                },
            }}
            onUpdateProductConfiguration={handleUpdatePositionProductConfiguration}
            onSubmit={handleSubmit}
        />
    ) : null;
};
