import { z } from 'zod';
import { PRODUCT_CATEGORY } from '../order-product/product-category.ts';

export const ORDER_ORGANIZATION_ROLE_SCHEMA = z.enum(['CUSTOMER', 'SUPPLIER', 'CARRIER', 'CUSTOM']);
export type ORDER_ORGANIZATION_ROLE = z.infer<typeof ORDER_ORGANIZATION_ROLE_SCHEMA>;

export const ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA = z.enum(['DEBTOR', 'CREDITOR']);
export type ORDER_ORGANIZATION_TRANSACTIONAL_ROLE = z.infer<typeof ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA>;

export const ORGANIZATION_ROLE_LABEL = {
    CUSTOMER: 'organization.roleName.customer',
    SUPPLIER: 'organization.roleName.supplier',
    CARRIER: 'organization.roleName.carrier',
    CUSTOM: 'organization.roleName.servicePartner',
} as const satisfies Record<ORDER_ORGANIZATION_ROLE, string>;

export type OrganizationProduct = {
    organizationId: number;
    product: PRODUCT_CATEGORY;
};

export type OrderOrganizationRoles = {
    [organizationId: number]: ORDER_ORGANIZATION_ROLE[];
};
