import { ProductGroupProps } from '@/shared/components/ProductSelection/ProductGroup.tsx';
import { GetProductGroupListResponse } from '@schuettflix/interfaces';

export function mapOrderProductGroups(
    orderProductGroups: GetProductGroupListResponse[]
): ProductGroupProps['productGroup'][] {
    return orderProductGroups.map(orderProductGroup => ({
        ...orderProductGroup,
        orderProducts: orderProductGroup.orderProducts?.map(orderProduct => ({
            ...orderProduct,
            productConfiguration: orderProduct.orderProductConfiguration,
        })),
    }));
}
