import { useEffect } from 'react';

function useDebounce<T>(value: T, delay: number = 1000, onChange?: (value: T) => void) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onChange?.(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, delay]);
}

export { useDebounce };
