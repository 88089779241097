import { z } from 'zod';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { FulfillmentProductConfigurationSchema } from './patch-product-configuration.ts';
import { FULFILLMENT_PRODUCT_SOURCE_SCHEMA } from './get-products.ts';

export const PostFulfillmentProductRequestSchema = z.object({
    orderProductId: z.string().uuid().nullish(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    productGroupId: z.string().uuid(),
    index: z.number(),
});

export const PostFulfillmentProductResponseSchema = z.object({
    id: z.string().uuid(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    productGroupId: z.string().uuid(),
    source: FULFILLMENT_PRODUCT_SOURCE_SCHEMA.nullable(),
    fulfillmentProductConfiguration: FulfillmentProductConfigurationSchema.nullable(),
    index: z.number(),
});

export type PostFulfillmentProductRequest = z.infer<typeof PostFulfillmentProductRequestSchema>;
export type PostFulfillmentProductResponse = z.infer<typeof PostFulfillmentProductResponseSchema>;
