import { useSellerProducts } from '@/clients/sellerProducts/useSellerProduct.ts';
import { InfoCard, LoadingSpinner } from '@schuettflix/react-components';
import { CreateFactoryProductForm } from '@/modules/supplierProductManagement/CreateFactoryProductForm.tsx';
import { EditFactoryProductForm } from '@/modules/supplierProductManagement/EditFactoryProductForm.tsx';

export function EditFactoryProductList({ factoryId }: { factoryId: number }) {
    const { data: sellerProductsData, isLoading } = useSellerProducts(factoryId);

    const sellerProducts = sellerProductsData?.items || [];

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <ul className="pb-4">
                {sellerProducts.map(product => (
                    <li className="py-1" key={product.id}>
                        <InfoCard className="flex justify-between" title={product.name}>
                            <EditFactoryProductForm product={product} factoryId={factoryId} />
                        </InfoCard>
                    </li>
                ))}
                {sellerProducts.length === 0 && <li>No products at this site</li>}
            </ul>
            <h2 className="font-headline-md">Add a Product</h2>
            <CreateFactoryProductForm factoryId={factoryId} />
        </>
    );
}
