import { PrefillTemplate } from '@/shared/types/prefill-template-data.ts';
import { TEMPLATE_GROUP_TITLE, TEMPLATE_GROUP_TITLE_LABEL } from '@schuettflix/interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useCreatePositionProductGroup } from './useCreatePositionProductGroup';
import { getPositionProductGroupsQueryKey } from './usePositionProductGroup';

export function useCreatePositionProductGroupsByTemplate(positionId: string) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const createPositionProductGroup = useCreatePositionProductGroup(positionId);

    const createProductGroups = async (template: PrefillTemplate) => {
        for (const [index, productGroup] of template.productGroups.entries()) {
            const selectedProductsArray = productGroup.orderProducts.map((selectedProduct, index) => {
                return {
                    index: index + 1,
                    category: selectedProduct.category,
                    type: selectedProduct.type,
                };
            });

            await createPositionProductGroup.mutateAsync({
                productGroup: {
                    name: t(TEMPLATE_GROUP_TITLE_LABEL[productGroup.name as TEMPLATE_GROUP_TITLE], ''),
                    type: template.type,
                    index: index,
                    templateId: template.templateId,
                    products: selectedProductsArray,
                },
            });
        }
        await queryClient.invalidateQueries({ queryKey: getPositionProductGroupsQueryKey(positionId) });
    };

    return useMutation({
        mutationKey: ['create-position-product-groups-by-template'],
        mutationFn: createProductGroups,
    });
}
