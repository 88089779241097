import { z } from 'zod';

export const FulfillmentProductConfigurationSchema = z.object({
    fulfillmentProductId: z.string().uuid(),
    showZeroPurchasePrice: z.boolean(),
    showZeroSalesPrice: z.boolean(),
});

export const PatchFulfillmentProductConfigurationUrlSchema = z.object({
    fulfillmentProductId: z.string().uuid(),
});

export const PatchFulfillmentProductConfigurationRequestSchema = z.object({
    showZeroPurchasePrice: z.boolean().optional(),
    showZeroSalesPrice: z.boolean().optional(),
});

export const PostFulfillmentProductConfigurationRequestSchema = PatchFulfillmentProductConfigurationRequestSchema;

export const PatchFulfillmentProductConfigurationResponseSchema = FulfillmentProductConfigurationSchema;

export type FulfillmentProductConfiguration = z.infer<typeof FulfillmentProductConfigurationSchema>;
export type PatchFulfillmentProductConfigurationUrl = z.infer<typeof PatchFulfillmentProductConfigurationUrlSchema>;
export type PatchFulfillmentProductConfigurationRequest = z.infer<
    typeof PatchFulfillmentProductConfigurationRequestSchema
>;
export type PatchFulfillmentProductConfigurationResponse = z.infer<
    typeof PatchFulfillmentProductConfigurationResponseSchema
>;
