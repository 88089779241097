import { z } from 'zod';

export const POSITION_PRODUCT_STATUS_SCHEMA = z.enum(['DRAFT', 'SUBMITTED']);
export type POSITION_PRODUCT_STATUS = z.infer<typeof POSITION_PRODUCT_STATUS_SCHEMA>;

export const floatingPointPrecisionSchema = z.number().refine(
    val => {
        return val >= 0 && Number.isFinite(val) && Math.abs(val - parseFloat(val.toFixed(3))) < 1e-10;
    },
    {
        message: 'Floating point numbers must be non-negative and have at most three decimal places!',
    }
);
