import { z } from 'zod';
import { GET_LINE_ITEMS_SCHEMA } from '../product-channel/get-line-item.ts';

export const GetOrderLineItemsUrlPathParametersSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const LineItemSchema = GET_LINE_ITEMS_SCHEMA;

export type LineItem = z.infer<typeof LineItemSchema>;

/**
 * validation schema for response payload
 */
export const GetLineItemListResponseSchema = LineItemSchema;

export type GetLineItemListResponse = z.infer<typeof GetLineItemListResponseSchema>;
