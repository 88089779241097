import { useSuspenseQuery } from '@tanstack/react-query';
import { getPositionById } from './position';

export const usePosition = (positionId?: string | null, fetchOnce = false) => {
    return useSuspenseQuery({
        queryKey: ['position', positionId],
        queryFn: async () => (positionId ? getPositionById(positionId) : null),
        ...(fetchOnce && { staleTime: Infinity, cacheTime: Infinity }),
        refetchOnWindowFocus: false,
    });
};
