import { Combobox } from '@headlessui/react';
import { TextField } from '@schuettflix/react-components';
import Fuse from 'fuse.js';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInternalRef } from '@/shared/hooks/useInternalRef.ts';
import { SearchOption } from '@/shared/components/SearchOption.tsx';
import {
    useConstructionProject,
    useFilteredConstructionProjects,
} from '@/clients/constructionProject/useConstructionProject.ts';
import {
    FilterOptionsConstructionProject,
    GetConstructionProjectsListEntity,
} from '@/clients/constructionProject/types/constructionProject.ts';

export interface SelectConstructionProjectProps {
    disabled?: boolean;
    organizationId: number | null;
    filters: FilterOptionsConstructionProject;
    value: string | null;
    criticalized?: boolean;
    helperText?: string;
    onChange: (constructionProjectId: string | null) => void;
}

export const SelectConstructionProject = forwardRef<HTMLInputElement, SelectConstructionProjectProps>(
    ({ onChange, disabled, value, criticalized, helperText, organizationId, filters }, forwardRef) => {
        const { t } = useTranslation();
        const ref = useInternalRef(forwardRef);
        const { data: constructionProjectsList, isFetched } = useFilteredConstructionProjects(organizationId, filters);
        const { data: selectedConstructionProject } = useConstructionProject(value);
        const [query, setQuery] = useState('');

        useEffect(() => {
            setQuery(selectedConstructionProject?.name ?? '');
        }, [selectedConstructionProject]);

        const constructionProjects = useMemo(
            () => constructionProjectsList?.constructionProjects ?? [],
            [constructionProjectsList]
        );

        const fuse = useMemo(
            () =>
                new Fuse(constructionProjects, {
                    keys: [
                        { name: 'name', weight: 1 },
                        { name: 'description', weight: 0.5 },
                        { name: 'projectCode', weight: 1 },
                    ],
                }),
            [constructionProjects]
        );

        const filteredConstructionProjects = useMemo(() => {
            if (!query) {
                return constructionProjects;
            }

            return fuse.search(query).map(result => result.item);
        }, [query, fuse, constructionProjects]);

        return (
            <div>
                <Combobox
                    value={selectedConstructionProject ?? null}
                    onChange={cp => {
                        if (!cp) return;
                        setQuery(cp.name);
                        onChange(cp.id);
                    }}
                    disabled={disabled}
                >
                    {/* https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969 */}
                    <Combobox.Button as="div">
                        <Combobox.Input
                            data-test="construction-project-input"
                            as={TextField}
                            ref={ref}
                            label={t('selectConstructionProject.label')}
                            value={query}
                            displayValue={(project?: GetConstructionProjectsListEntity) => project?.name ?? ''}
                            errorMessage={(criticalized && helperText) || undefined}
                            onClick={() => {
                                ref.current?.select();
                            }}
                            onChange={event => {
                                const { value } = event.target;
                                setQuery(value);
                                if (value.length === 0) {
                                    onChange(null);
                                }
                            }}
                        />
                    </Combobox.Button>
                    <div className="relative" data-test="construction-project-dropdown">
                        <Combobox.Options className="scroll font-copy-md bg-surface shadow-high absolute top-2 z-10 max-h-[250px] w-full overflow-y-auto rounded">
                            {filteredConstructionProjects.length === 0 && isFetched ? (
                                <Combobox.Option value={null} disabled>
                                    <SearchOption
                                        active={false}
                                        selected={false}
                                        title={t('errorMessages.common.noResults')}
                                    />
                                </Combobox.Option>
                            ) : (
                                filteredConstructionProjects.map(constructionProject => (
                                    <Combobox.Option key={constructionProject.id} value={constructionProject}>
                                        {props => (
                                            <SearchOption
                                                {...props}
                                                title={constructionProject.name}
                                                side={constructionProject.projectCode}
                                                description={constructionProject.description}
                                            />
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>
        );
    }
);
