import { LanguageSwitcher } from '@/shared/components/LanguageSwitcher.tsx';
import { ResourceCenter } from '@/shared/components/ResourceCenter.tsx';
import React from 'react';
import { CloseIcon } from '@schuettflix/icons-react';
import { IconButton } from '@schuettflix/react-components';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';

interface PageHeaderProps {
    title: string;
    additionalContent?: React.ReactNode;
}

export const PageHeader = ({ title, additionalContent }: PageHeaderProps) => {
    const { openUrl: openOverviewPage } = useMonolithFrontendUrl({
        target: 'currentTab',
    });

    const handleCloseClick = () => {
        const pathName = window.location.pathname;
        let redirectTo: string | undefined;

        if (pathName.includes('order')) {
            redirectTo = '/dashboard';
        }
        if (pathName.includes('fulfillment')) {
            redirectTo = '/order-management/orders';
        }
        if (pathName.includes('position')) {
            redirectTo = '/order-management/projects';
        }

        openOverviewPage({ path: redirectTo });
    };

    return (
        <>
            <header className="bg-surface h-18 fixed inset-x-0 top-0 z-20 flex items-center justify-between border-b px-6">
                <div className="font-copy-sm text-subdued flex flex-col justify-between">
                    <div className="flex gap-2">
                        <h1 className="font-headline-lg-strong">{title}</h1>
                        {additionalContent}
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <ResourceCenter />
                    <LanguageSwitcher />
                    <IconButton icon={CloseIcon} onClick={handleCloseClick} />
                </div>
            </header>
            <div className="h-18" />
        </>
    );
};
