import { z } from 'zod';
import { PostPositionOrderResponseSchema } from '../order/post-position-order.ts';

export const CreatePositionOrderUrlSchema = z.strictObject({
    positionId: z.string().uuid(),
});

export const CreatePositionOrderResponseSchema = PostPositionOrderResponseSchema;

export type CreatePositionOrderUrl = z.infer<typeof CreatePositionOrderUrlSchema>;
