import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropzone, File } from '@schuettflix/react-components';
import { useFileUpload } from '@/shared/hooks/useFileUpload.ts';
import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { useForceRerender } from '@/shared/hooks/useForceRerender.ts';
import { useSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { useFulfillmentSectionsErrors } from '../hooks/useFulfillmentSectionsErrors.ts';
import { isPdfEncrypted } from '@/shared/utils/isPdfEncrypted.ts';
import { cn } from '@/shared/utils/cn.ts';

const MAX_FILE_SIZE_IN_BYTES = 20 * 1024 * 1024;

interface UploadFile {
    file: File;
    progress: number;
    remotePath: string;
    status: 'pending' | 'uploading' | 'uploaded' | 'failed';
    error?: string;
    abortController: AbortController;
}

interface DocumentUploadSectionProps {
    disabled?: boolean;
}

export const DocumentUploadSection: React.FC<DocumentUploadSectionProps> = ({ disabled }) => {
    const { t } = useTranslation();
    const { uploadFile } = useFileUpload();
    const { documents, setDocuments, errors, isSubmitted } = useFulfillmentContext();

    const forceRerender = useForceRerender();

    const [uploads, setUploads] = useState<UploadFile[]>([]);

    useEffect(() => {
        async function uploadPendingFiles() {
            const pendingFiles = uploads.filter(file => file.status === 'pending');
            const uploadPromises = pendingFiles.map(async file => {
                file.status = 'uploading';
                file.abortController = new AbortController();

                // Do not allow encrypted PDFs
                const isEncrypted = await isPdfEncrypted(file.file);
                if (isEncrypted) {
                    file.error = t('fulfillment.documentUpload.errors.pdfIsEncrypted');
                    file.status = 'failed';
                    return;
                }

                try {
                    file.remotePath = await uploadFile(file.file, file.abortController.signal, progress => {
                        file.progress = progress;
                        forceRerender();
                    });
                    file.status = 'uploaded';
                } catch (e) {
                    file.error = ' ';
                    file.status = 'failed';
                    console.error(e);
                }
            });

            await Promise.allSettled(uploadPromises);
            if (uploads.every(upload => upload.status === 'uploaded')) {
                setDocuments(uploads.map(upload => ({ url: upload.remotePath, fileName: upload.file.name })));
            }
        }

        void uploadPendingFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploads, setDocuments, uploadFile, forceRerender]);

    const hasFileUploadErrors = uploads.some(file => file.status === 'failed');

    useFulfillmentSectionsErrors([
        ...(documents.length === 0 && !hasFileUploadErrors
            ? [
                  {
                      field: 'documentUrls',
                      message: t('fulfillment.error.noDocuments'),
                      scrollToElement: () => {
                          document.getElementById('documentUploadSection')?.scrollIntoView({ behavior: 'smooth' });
                      },
                  },
              ]
            : []),
        ...(hasFileUploadErrors
            ? [
                  {
                      field: 'documentUrls',
                      message: t('fulfillment.error.invalidDocuments'),
                      scrollToElement: () => {
                          document.getElementById('documentUploadSection')?.scrollIntoView({ behavior: 'smooth' });
                      },
                  },
              ]
            : []),
    ]);

    useSectionsValidity('document-upload', !hasFileUploadErrors && documents.length > 0);

    const hasUploadErrors = () => errors.some(error => error.field === 'documentUrls');

    return (
        <div id="documentUploadSection">
            <Dropzone
                accept="image/*, application/pdf"
                data-test="document-upload"
                onChange={fileList =>
                    setUploads([
                        ...uploads,
                        ...Array.from(
                            fileList,
                            file => ({ file, progress: 0, remotePath: '', status: 'pending' }) as UploadFile
                        ),
                    ])
                }
                translations={{
                    label: t('fulfillment.documentUpload.label'),
                    info: t('fulfillment.documentUpload.info'),
                    drop: t('fulfillment.documentUpload.drop'),
                    errors: {
                        maxOneFile: '', // not happening if multiple is set
                        invalidFileSize: () => t('fulfillment.documentUpload.errors.fileSize', { size: 20 }).toString(),
                        invalidFileType: () => t('fulfillment.documentUpload.errors.fileType'),
                    },
                }}
                maxFileSize={MAX_FILE_SIZE_IN_BYTES}
                multiple
                className={cn('mb-4', { 'border-critical border': hasUploadErrors() && isSubmitted })}
                disabled={disabled}
            />
            <div className="flex flex-col gap-4">
                {isSubmitted &&
                    errors
                        .filter(error => error.field === 'documentUrls')
                        .map((error, index) => (
                            <p className="font-copy-sm text-critical" key={index}>
                                {error.message}
                            </p>
                        ))}
                {uploads.map((file, index) => (
                    <File
                        file={file.file}
                        key={index}
                        upload={
                            file.status === 'uploading'
                                ? {
                                      progress: file.progress,
                                      error: file.error,
                                      onCancel: () => {
                                          file.abortController.abort();
                                          file.status = 'failed';
                                      },
                                      onRetry: () => {},
                                  }
                                : file.status === 'failed'
                                  ? {
                                        progress: 100,
                                        error: file.error,
                                        onCancel: () => {
                                            setUploads(files => files.filter(f => f !== file));
                                        },
                                        onRetry: () => {},
                                    }
                                  : undefined
                        }
                        onDelete={() => {
                            setUploads(files => files.filter(f => f !== file));
                        }}
                    />
                ))}
            </div>
        </div>
    );
};
