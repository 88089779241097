import { z } from 'zod';
import { NestedProductQuoteResponseSchema } from '../product-quote/NestedQuoteResponseEntity.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { NestedProductTypeResponseSchema } from '../product-type/NestedTypeResponseEntity.ts';
import { PRODUCT_CATEGORY_SCHEMA } from './product-category.ts';

export const NestedOrderProductConfigurationSchema = z.strictObject({
    orderProductId: z.string().uuid(),
    showZeroPurchasePrice: z.boolean(),
    showZeroSalesPrice: z.boolean(),
});

export const NestedOrderProductResponseSchema = z.strictObject({
    id: z.string(),
    orderProductGroupId: z.string(),
    positionProductId: z.string().uuid().nullable(),
    templateId: z.string().nullable(),
    index: z.number(),
    orderProductConfiguration: NestedOrderProductConfigurationSchema.nullable(),
    // TODO get rid of type as it is already in the productType object
    type: PRODUCT_TYPE_SCHEMA,
    category: PRODUCT_CATEGORY_SCHEMA,
    productType: NestedProductTypeResponseSchema.optional(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    quote: NestedProductQuoteResponseSchema.nullable(),
});

export type NestedOrderProductResponse = z.infer<typeof NestedOrderProductResponseSchema>;
export type NestedOrderProductConfiguration = z.infer<typeof NestedOrderProductConfigurationSchema>;
