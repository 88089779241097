import { z } from 'zod';

// NOTE: Parsing multi-value query parameters requires two schemas. For details check API handler implementation.
export const GetPositionsCountsQueryParamsSchema = z.strictObject({
    ['constructionProjectIds']: z.string(),
});
export const GetPositionsCountsQueryParamConstructionProjectIdsSchema = z.string().uuid().array().nonempty();

export const GetPositionsCountsResponseMapValueSchema = z.strictObject({
    total: z.number(),
    draft: z.number(),
    active: z.number(),
    inactive: z.number(),
    closed: z.number(),
});

export const GetPositionsCountsResponseSchema = z.record(z.string().uuid(), GetPositionsCountsResponseMapValueSchema);
