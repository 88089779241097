import { z } from 'zod';
import { NestedPositionProductConfigurationSchema } from './nested-position-product-configuration.ts';

export const PatchPositionProductConfigurationUrlSchema = z.strictObject({
    positionProductId: z.string().uuid(),
});

export const PatchPositionProductConfigurationRequestSchema = z.strictObject({
    showZeroPurchasePrice: z.boolean().optional(),
    showZeroSalesPrice: z.boolean().optional(),
});

export const PatchPositionProductConfigurationResponseSchema = NestedPositionProductConfigurationSchema;

export type PatchPositionProductConfigurationRequest = z.infer<typeof PatchPositionProductConfigurationRequestSchema>;
export type PatchPositionProductConfigurationResponse = z.infer<typeof NestedPositionProductConfigurationSchema>;
export type PatchPositionProductConfigurationUrl = z.infer<typeof PatchPositionProductConfigurationUrlSchema>;
