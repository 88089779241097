import { CustomRequestProductFormProvider } from '@/CustomRequestProductChannel/components/CustomRequestProductFormProvider.tsx';
import {
    FulfillmentCustomRequestProductManager,
    FulfillmentCustomRequestProductManagerProps,
} from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProductManager.tsx';

type FulfillmentCustomRequestProductProps = FulfillmentCustomRequestProductManagerProps;

export function FulfillmentCustomRequestProduct(props: FulfillmentCustomRequestProductProps) {
    return (
        <CustomRequestProductFormProvider>
            <FulfillmentCustomRequestProductManager {...props} />
        </CustomRequestProductFormProvider>
    );
}
