import { Combobox } from '@headlessui/react';
import { TextField } from '@schuettflix/react-components';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterOrganizations, useOrganization } from '@/clients/organization/useOrganization.ts';

import { FilterOptionsOrganization, Organization } from '@/clients/organization/Organization.ts';
import { useInternalRef } from '@/shared/hooks/useInternalRef.ts';
import { SearchOption } from '@/shared/components/SearchOption.tsx';

type FilterableOrganizationProps = Pick<Organization, 'vatId' | 'taxId' | 'id'>;

export interface SelectOrganizationProps {
    className?: string;
    disabled?: boolean;
    label?: string;
    value: Organization['id'] | null;
    critical?: boolean;
    helperText?: string;
    onChange: (organizationId: Organization['id'] | null) => void;
    filters: FilterOptionsOrganization;
    excludeMatchingIds?: Partial<FilterableOrganizationProps>;
    title?: string;
    size?: 'sm' | 'md';
    placeholder?: string;
    selectedOrganizationId?: Organization['id'] | undefined | null;
    dataTest?: string;
}

const filterOrganizationsByMatchingFields = (
    organizations: Organization[],
    excludeMatchingIds: Partial<FilterableOrganizationProps>
): Organization[] => {
    const keysFilter = Object.keys(excludeMatchingIds) as (keyof FilterableOrganizationProps)[];
    return organizations.filter(organization => !keysFilter.some(key => organization[key] === excludeMatchingIds[key]));
};

export const SelectOrganization = forwardRef<HTMLInputElement, SelectOrganizationProps>(
    (
        {
            filters,
            onChange,
            disabled,
            critical,
            helperText,
            label,
            excludeMatchingIds,
            size,
            placeholder,
            className,
            selectedOrganizationId,
            dataTest,
        },
        forwardRef
    ) => {
        const { t } = useTranslation();
        const ref = useInternalRef(forwardRef);

        const { data: selectedOrganization } = useOrganization(selectedOrganizationId);

        const [organization, setOrganization] = useState<Organization | null>(selectedOrganization || null);
        const [query, setQuery] = useState(selectedOrganization?.name ?? '');

        useEffect(() => {
            if (selectedOrganization?.name && query !== selectedOrganization.name) {
                setQuery(selectedOrganization.name);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedOrganization?.name]);

        const { data, isFetched } = useFilterOrganizations({
            ...filters,
            search: query,
        });

        let options = data ? data.items : [];

        if (excludeMatchingIds) {
            options = filterOrganizationsByMatchingFields(options, excludeMatchingIds);
        }

        return (
            <div className={className}>
                <Combobox
                    value={organization}
                    onChange={organization => {
                        if (!organization) return;
                        setQuery(organization.name);
                        setOrganization(organization);
                        onChange(organization.id);
                    }}
                    disabled={disabled}
                >
                    {/* https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969 */}
                    <Combobox.Button as="div">
                        <Combobox.Input
                            title={query}
                            inputClassName="truncate"
                            data-test={dataTest}
                            as={TextField}
                            variant={size}
                            placeholder={placeholder}
                            ref={ref}
                            label={label || ''}
                            value={query}
                            displayValue={(org?: Organization) => org?.name ?? ''}
                            errorMessage={critical ? helperText : undefined}
                            onClick={() => {
                                ref.current?.select();
                            }}
                            onChange={event => {
                                const { value } = event.target;
                                setQuery(value);
                                if (value.length === 0) {
                                    onChange(null);
                                }
                            }}
                        />
                    </Combobox.Button>
                    <div className="relative" data-test="organization-list">
                        <Combobox.Options className="scroll font-copy-md bg-surface shadow-high absolute top-2 z-10 max-h-[250px] w-full overflow-y-auto rounded">
                            {options.length === 0 && isFetched ? (
                                <Combobox.Option value={null} disabled>
                                    <SearchOption
                                        active={false}
                                        selected={false}
                                        title={t('errorMessages.common.noResults') ?? ''}
                                    />
                                </Combobox.Option>
                            ) : (
                                options.map(org => (
                                    <Combobox.Option key={org.id} value={org}>
                                        {props => (
                                            <SearchOption
                                                {...props}
                                                title={org.name}
                                                description={
                                                    <address className="font-normal not-italic">{`${org.billingAddress.street} ${org.billingAddress.number}, ${org.billingAddress.zip} ${org.billingAddress.city}, ${org.billingAddress.country}`}</address>
                                                }
                                                side={org.id.toString()}
                                            />
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>
        );
    }
);
