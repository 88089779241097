import React, { useState } from 'react';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { GetProductGroupResponseSchema, NestedOrderProductResponse } from '@schuettflix/interfaces';
import { z } from 'zod';
import {
    ProductGroupHeader,
    ProductGroupHeaderProps,
} from '@/shared/components/ProductGroupHeader/ProductGroupHeader.tsx';
import { AddProductButton } from '@/shared/components/AddProductButton/AddProductButton.tsx';
import { BaseCustomRequestProductProps } from '@/CustomRequestProductChannel/components/CustomRequestProduct';
import { SimpleDebouncedCustomRequestProductProps } from '@/CustomRequestProductChannel/components/DebouncedCustomRequestProduct';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { useFrancoSync, useMerchantSync } from './hooks/useProductGroupSync';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag';

type OrderProduct = SimpleDebouncedCustomRequestProductProps['product'] &
    Pick<NestedOrderProductResponse, 'category' | 'templateId' | 'index'>;

export type ProductGroupProps = {
    productGroup: Omit<z.output<typeof GetProductGroupResponseSchema>, 'orderId' | 'orderProducts'> & {
        orderProducts?: OrderProduct[] | undefined;
    };
    onAddPosition: (product: Product) => void;
    onDeleteProduct: (productId: string) => void;
    onProductGroupNameChange: (updatedProductGroupName?: string) => void;
    productRender: (props: Omit<SimpleDebouncedCustomRequestProductProps, 'onCompleteChange'>) => React.ReactNode;
    showValidation: boolean;
};

export const ProductGroup: React.FC<ProductGroupProps> = ({
    productGroup,
    onAddPosition,
    onProductGroupNameChange,
    onDeleteProduct,
    productRender: CustomRequestProductElement,
    showValidation,
}) => {
    const { isEditing } = useEditPositionState();
    const francoSync = useFrancoSync();
    const merchantSync = useMerchantSync();
    const merchantSplitEnabled = useFeatureFlag<boolean>('genericOrderTemplatesNewMerchantSplit', false);

    const [intermediateOrderProducts, setintermediateOrderProducts] = useState<ProductGroupHeaderProps['products']>([]);

    const updateProductQuote: NonNullable<BaseCustomRequestProductProps['onFormChange']> = ({ values, productId }) => {
        const product = productGroup.orderProducts?.find(p => p.id === productId);
        if (!product) return;
        setintermediateOrderProducts(p => {
            const products = [...p];
            const index = products.findIndex(({ id }) => id === productId);
            const intermediateProduct = products[index];

            const quote = {
                salesPrice: Number(values.salesPrice),
                purchasePrice: Number(values.purchasePrice),
                amount: values.amount,
                currencyCode: values.currencyCode ?? 'EUR',
                unit: values.unit,
            };

            if (intermediateProduct) {
                products[index] = {
                    ...intermediateProduct,
                    quote,
                };
                return products;
            }

            return [...products, { ...product, quote }];
        });
    };

    const isPositionOrder = productGroup.orderProducts?.some(product => product.positionProductId);

    function isNonDeletableProduct(product: OrderProduct): boolean {
        return isPositionOrder ? productGroup.orderProducts!.length === 1 : !!product.templateId;
    }

    const handleDeleteProduct = (productId: string) => {
        const productToBeDeleted = productGroup.orderProducts?.find(p => p.id === productId);

        if (!productToBeDeleted || isNonDeletableProduct(productToBeDeleted)) {
            return;
        }

        onDeleteProduct(productId);
        setintermediateOrderProducts(previousIntermediateOrderProducts =>
            previousIntermediateOrderProducts.filter(p => p.id !== productId)
        );
    };

    function renderProducts() {
        switch (productGroup.type) {
            case 'FRANCO': {
                const materialProduct = productGroup.orderProducts?.find(product => product.category === 'MATERIAL');
                const transportProduct = productGroup.orderProducts?.find(product => product.category === 'TRANSPORT');

                if (!materialProduct || !transportProduct) {
                    return null;
                }

                const remainingProducts =
                    productGroup.orderProducts?.filter(
                        product => product.id !== materialProduct.id && product.id !== transportProduct.id
                    ) ?? [];

                return (
                    <>
                        <CustomRequestProductElement
                            intersectionSchema={francoSync.FrancoSchema}
                            leadingValues={francoSync.leadingValues}
                            product={materialProduct}
                            templateId={materialProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => francoSync.storeProductFormValues(materialProduct, values)}
                            formFieldSettings={{
                                salesPrice: { isInvalid: !francoSync.allSalesPricesHaveSameSign },
                            }}
                            showProductPriceAddedHint
                            showValidation={showValidation}
                            disabledFields={{ unit: isEditing }}
                            showZeroPriceProductConfiguration={false}
                        />
                        <CustomRequestProductElement
                            intersectionSchema={francoSync.FrancoSchema}
                            product={transportProduct}
                            formFieldSettings={{
                                salesPrice: { isInvalid: !francoSync.allSalesPricesHaveSameSign },
                            }}
                            templateId={transportProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => francoSync.storeProductFormValues(transportProduct, values)}
                            leadingValues={francoSync.leadingValues}
                            showProductPriceAddedHint
                            disabledFields={{ amount: true, unit: true }}
                            showValidation={showValidation}
                            showZeroPriceProductConfiguration={false}
                        />
                        {remainingProducts.map(product => (
                            <CustomRequestProductElement
                                key={product.id}
                                product={product}
                                templateId={productGroup.templateId}
                                enableDelete
                                showProductPriceNotAddedHint
                                onDeleteProduct={handleDeleteProduct}
                                onFormChange={updateProductQuote}
                                showValidation={showValidation}
                                disabledFields={{ unit: isEditing }}
                            />
                        ))}
                    </>
                );
            }
            case 'STANDARD': {
                return (
                    <>
                        {(productGroup.orderProducts ?? []).map(product => (
                            <CustomRequestProductElement
                                key={product.id}
                                product={product}
                                enableDelete={!isNonDeletableProduct(product)}
                                onDeleteProduct={handleDeleteProduct}
                                templateId={productGroup.templateId}
                                onFormChange={updateProductQuote}
                                showProductPriceNotAddedHint={
                                    (product.category === 'MATERIAL_SERVICE' ||
                                        product.category === 'TRANSPORT_SERVICE') &&
                                    productGroup.type === 'MERCHANT'
                                }
                                showValidation={showValidation}
                                disabledFields={{ unit: isEditing }}
                            />
                        ))}
                    </>
                );
            }

            case 'MERCHANT': {
                if (!merchantSplitEnabled) {
                    return (
                        <>
                            {(productGroup.orderProducts ?? []).map(product => (
                                <CustomRequestProductElement
                                    key={product.id}
                                    product={product}
                                    enableDelete={!isNonDeletableProduct(product)}
                                    onDeleteProduct={handleDeleteProduct}
                                    templateId={productGroup.templateId}
                                    onFormChange={updateProductQuote}
                                    showProductPriceNotAddedHint={
                                        product.category === 'MATERIAL_SERVICE' ||
                                        product.category === 'TRANSPORT_SERVICE'
                                    }
                                    showValidation={showValidation}
                                    disabledFields={{ unit: isEditing }}
                                />
                            ))}
                        </>
                    );
                }

                const materialProduct = productGroup.orderProducts?.find(product => product.category === 'MATERIAL');
                const transportProduct = productGroup.orderProducts?.find(product => product.category === 'TRANSPORT');

                if (!materialProduct || !transportProduct) {
                    return null;
                }

                const remainingProducts =
                    productGroup.orderProducts?.filter(
                        product => product.id !== materialProduct.id && product.id !== transportProduct.id
                    ) ?? [];

                return (
                    <>
                        <CustomRequestProductElement
                            leadingValues={merchantSync.leadingValues}
                            product={materialProduct}
                            templateId={materialProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => merchantSync.storeProductFormValues(materialProduct, values)}
                            showProductPriceAddedHint
                            showValidation={showValidation}
                            disabledFields={{ unit: isEditing }}
                            showZeroPriceProductConfiguration={false}
                        />
                        <CustomRequestProductElement
                            product={transportProduct}
                            templateId={transportProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => merchantSync.storeProductFormValues(transportProduct, values)}
                            leadingValues={{
                                ...merchantSync.leadingValues,
                                purchasePrice: '0',
                                salesPrice: '0',
                            }}
                            showProductPriceAddedHint
                            disabledFields={{
                                unit: true,
                                amount: true,
                                partnerOrganizationId: true,
                                salesPrice: true,
                                salesTaxClassId: true,
                                purchasePrice: true,
                                purchaseTaxClassId: true,
                                serviceDate: true,
                                productName: true,
                            }}
                            showValidation={showValidation}
                            showZeroPriceProductConfiguration={false}
                        />
                        {remainingProducts.map(product => (
                            <CustomRequestProductElement
                                key={product.id}
                                product={product}
                                templateId={productGroup.templateId}
                                enableDelete
                                showProductPriceNotAddedHint
                                onDeleteProduct={handleDeleteProduct}
                                onFormChange={updateProductQuote}
                                showValidation={showValidation}
                                disabledFields={{ unit: isEditing }}
                            />
                        ))}
                    </>
                );
            }
        }
    }

    return (
        <div className="bg-surface mb-12 rounded last:mb-0">
            <div className="border-b p-4">
                <ProductGroupHeader
                    productGroupName={productGroup.name ?? undefined}
                    productGroupType={productGroup.type}
                    productGroupTemplateId={productGroup.templateId}
                    products={intermediateOrderProducts}
                    onProductGroupNameChange={onProductGroupNameChange}
                />
            </div>
            <div className="flex flex-col divide-y [counter-reset:productCounter]">{renderProducts()}</div>

            <div className="flex w-full border-t">
                <AddProductButton templateId={productGroup.templateId} onAddProduct={onAddPosition} />
            </div>
        </div>
    );
};
