import { CURRENCY, GetQuoteListByOrderIdResponsePayload } from '@schuettflix/interfaces';
import { FulfillmentQuote } from '../context/FulfillmentContext';
import { Price } from '@schuettflix/conversion';
import { z } from 'zod';

export function mapToFulfillmentQuote(
    quote: z.infer<typeof GetQuoteListByOrderIdResponsePayload>['payload']
): FulfillmentQuote {
    return {
        id: quote.id,
        name: quote.name,
        amount: quote.amount,
        orderProductId: quote.orderProductId,
        unit: quote.unit,
        currencyCode: quote.currencyCode as CURRENCY,
        partnerOrganizationId: quote.partnerOrganizationId,
        orderingOrganizationId: quote.orderingOrganizationId,
        platformOrganizationId: quote.platformOrganizationId,
        purchasePrice: new Price(quote.purchasePrice, quote.currencyCode as CURRENCY),
        purchaseTaxClassId: quote.purchaseTaxClassId,
        salesPrice: new Price(quote.salesPrice, quote.currencyCode as CURRENCY),
        salesTaxClassId: quote.salesTaxClassId,
        serviceDate: quote.serviceDate?.toISOString(),
    };
}
