import { z } from 'zod';
import { BILLING_TYPE_SCHEMA } from '../finance-settings/billing-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';

export const CalculatePaymentTermsSchema = z.array(
    z.object({
        organizationId: z.number(),
        paymentTermId: z.number().nullable(),
        billingMethod: BILLING_TYPE_SCHEMA,
    })
);

export type GetPaymentTermByOrganizationResponseSchema = z.infer<typeof CalculatePaymentTermsSchema>;

export const GetPaymentTermByOrganizationUrlPathParametersSchema = z.object({
    orderId: z.string().uuid(),
});

export const GetPaymentTermByOrganizationQueryParametersSchema = z.object({
    customerOrganizationId: z.string(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    organizationIds: z.string(),
});
