import { z } from 'zod';
import { ORDER_PROCESS_STATUS_SCHEMA } from './get-order-status';
import { order } from '@schuettflix/schema-registry-ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category';
import { CURRENCY_SCHEMA } from '../currency/currency';

const { IsoDate, IsoDatetimeMaybeRange } = order.shared.versions.v1.schemas;

export const OrderDetailsClientProductSchema = z.object({
    id: z.string(),
    name: z.string(),
    unit: SUPPORTED_UNITS_SCHEMA,
    quantity: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
});

export const OrderDetailsLineItemSchema = z.object({
    id: z.string(),
    debtorId: z.number(),
    creditorId: z.number(),
    quantity: z.number().nonnegative(),
    unit: SUPPORTED_UNITS_SCHEMA,
    price: z.number().nonnegative(),
    name: z.string(),
    currencyCode: CURRENCY_SCHEMA,
});

export const GetOrderDetailsUrlSchema = z.object({
    orderId: z.string().uuid(),
});

export const GetOrderDetailsResponseSchema = z.object({
    orderStatus: ORDER_PROCESS_STATUS_SCHEMA,
    closedAt: IsoDate.nullable(),

    createdAt: IsoDate,
    createdBy: z.number(),
    createdFor: z.number().nullable(),
    closedBy: z.number().nullable(),

    serviceDate: IsoDatetimeMaybeRange,
    humanReadableId: z.string(),

    customerOrganizationId: z.number(),
    constructionProjectId: z.string(),
    costCenters: z.array(z.string()).nullable(),

    clientProducts: z.array(OrderDetailsClientProductSchema),
    lineItems: z.object({
        orderedLineItems: z.array(OrderDetailsLineItemSchema),
        fulfilledLineItems: z.array(OrderDetailsLineItemSchema).nullish(),
    }),
});

export type GetOrderDetailsResponse = z.infer<typeof GetOrderDetailsResponseSchema>;

export type OrderDetailsClientProduct = z.infer<typeof OrderDetailsClientProductSchema>;

export type OrderDetailsLineItem = z.infer<typeof OrderDetailsLineItemSchema>;
