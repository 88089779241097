import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { NestedPositionProductConfigurationSchema } from './nested-position-product-configuration.ts';

export const NestedPositionProductSchema = z.strictObject({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    positionProductConfiguration: NestedPositionProductConfigurationSchema,
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
