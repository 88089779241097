import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import {
    getConstructionProjectById,
    getConstructionProjectListByOrganizationIdAndStatusIn,
} from './ConstructionProject';
import {
    FilterOptionsConstructionProject,
    GetConstructionProjectResponse,
} from '@/clients/constructionProject/types/constructionProject.ts';

export function useConstructionProject(
    id: string | null | undefined,
    options?: UseQueryOptions<GetConstructionProjectResponse>
) {
    return useQuery({
        queryKey: ['constructionProject', id!],
        queryFn: () => getConstructionProjectById(id!),
        enabled: !!id,
        refetchOnWindowFocus: false,
        ...options,
    });
}

export function useConstructionProjectSuspenseQuery(id: string | null | undefined) {
    return useSuspenseQuery({
        queryKey: ['constructionProject', id],
        queryFn: () => (id ? getConstructionProjectById(id) : null),
        refetchOnWindowFocus: false,
    });
}

export function useFilteredConstructionProjects(
    organizationId: number | null,
    filterOptions: FilterOptionsConstructionProject
) {
    return useQuery({
        queryKey: ['constructionProjects', organizationId, filterOptions],
        queryFn: () => {
            if (!organizationId) {
                return Promise.resolve({ constructionProjects: [] });
            }
            return getConstructionProjectListByOrganizationIdAndStatusIn(organizationId, filterOptions);
        },
        enabled: !!organizationId,
        refetchOnWindowFocus: false,
    });
}
