import { z } from 'zod';
import { QUOTE_STATUS_SCHEMA } from './quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';

/**
 * abstract quote validation schema for get response payload
 */
export const AbstractGetQuoteResponseSchema = z.object({
    meta: z.object({
        // quote information
        id: z.string(),
        status: QUOTE_STATUS_SCHEMA,
        createdAt: z.coerce.date(),
        updatedAt: z.coerce.date(),
        // order information
        orderId: z.string().uuid(),
        orderProductGroupId: z.string().uuid(),
        orderProductId: z.string().uuid(),
        positionProductId: z.string().uuid().nullable(),
        // product information
        amount: z.number(),
        unit: SUPPORTED_UNITS_SCHEMA,
        serviceDate: z.coerce.date(),
        productCategory: PRODUCT_CATEGORY_SCHEMA,
        productType: PRODUCT_TYPE_SCHEMA,
        // payment information
        partnerOrganizationId: z.number().int().positive().nullable(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        purchasePrice: z.number(),
        purchaseTaxClassId: z.string(),
        salesPrice: z.number(),
        salesTaxClassId: z.string(),
        currencyCode: z.string(),
    }),
    // will be overwritten by custom fulfillment channel payload
    payload: z.any(),
});
