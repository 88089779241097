import { createContext, ReactNode, useState } from 'react';
import { useBeforeUnloadWarning } from '@/shared/hooks/useBeforeUnloadWarning.ts';

interface BeforeUnloadContextValue {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
}

export const BeforeUnloadContext = createContext<BeforeUnloadContextValue>({
    enabled: false,
    setEnabled: () => {
        throw new Error('BeforeUnloadContextProvider not found');
    },
});

interface BeforeUnloadContextProviderProps {
    defaultEnabled: boolean;
    children: ReactNode;
}

export function BeforeUnloadContextProvider(props: BeforeUnloadContextProviderProps) {
    const [enabled, setEnabled] = useState(props.defaultEnabled);

    useBeforeUnloadWarning(enabled);

    return (
        <BeforeUnloadContext.Provider value={{ enabled, setEnabled }}>{props.children}</BeforeUnloadContext.Provider>
    );
}
