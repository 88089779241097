import { ExternalGlobals, resolveSflxEnv } from '@/shared/hooks/useGlobals';
import { getUrls } from '@schuettflix/urls';
import axios, { AxiosInstance } from 'axios';
import { authTokenFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { getMarketCodeFromLocalStorage } from '@/shared/context/MarketContext.tsx';

let constructionProjectClient: AxiosInstance | null;
let customRequestClient: AxiosInstance | null;
let monolithClient: AxiosInstance | null;
let orderClient: AxiosInstance | null;
let positionClient: AxiosInstance | null;
let fulfillmentClient: AxiosInstance | null;

function assertNotNull<T>(value: T | null): asserts value is T {
    if (value === null) {
        throw new Error('value is null');
    }
}

export function getConstructionProjectClient() {
    assertNotNull(constructionProjectClient);
    constructionProjectClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    constructionProjectClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return constructionProjectClient;
}

export function getCustomRequestClient() {
    assertNotNull(customRequestClient);
    customRequestClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    customRequestClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return customRequestClient;
}

export function getMonolithClient() {
    assertNotNull(monolithClient);
    monolithClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    monolithClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return monolithClient;
}

export function getOrderClient() {
    assertNotNull(orderClient);
    orderClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    orderClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return orderClient;
}

export function getPositionClient() {
    assertNotNull(positionClient);
    positionClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    positionClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return positionClient;
}

export function getFulfillmentClient() {
    assertNotNull(fulfillmentClient);
    fulfillmentClient.defaults.headers['X-Operating-Market'] = getMarketCodeFromLocalStorage();
    fulfillmentClient.defaults.headers['Authorization'] = `Bearer ${authTokenFromLocalStorage()}`;
    return fulfillmentClient;
}

function installMonolithClient(globals: ExternalGlobals) {
    monolithClient = axios.create({
        baseURL: globals.baseUrl.backend.value,
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
            'X-Operating-Market': 'DE',
        },
    });

    const cleanupBaseUrl = globals.baseUrl.backend.onUpdate(value => {
        assertNotNull(monolithClient);
        monolithClient.defaults.baseURL = value;
    });

    const cleanupUserLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(monolithClient);
        monolithClient.defaults.headers['Accept-Language'] = value;
    });

    const cleanupAccessToken = globals.accessToken.onUpdate(value => {
        assertNotNull(monolithClient);
        monolithClient.defaults.headers['Authorization'] = `Bearer ${value}`;
    });

    return () => {
        cleanupBaseUrl();
        cleanupUserLocale();
        cleanupAccessToken();
        monolithClient = null;
    };
}

function installConstructionProjectClient(globals: ExternalGlobals) {
    constructionProjectClient = axios.create({
        baseURL: globals.baseUrl.constructionProject.value,
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
            'X-Operating-Market': 'DE',
        },
    });

    const cleanupBaseUrl = globals.baseUrl.constructionProject.onUpdate(value => {
        assertNotNull(constructionProjectClient);
        constructionProjectClient.defaults.baseURL = value;
    });

    const cleanupUserLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(constructionProjectClient);
        constructionProjectClient.defaults.headers['Accept-Language'] = value;
    });

    const cleanupAccessToken = globals.accessToken.onUpdate(value => {
        assertNotNull(constructionProjectClient);
        constructionProjectClient.defaults.headers.Authorization = `Bearer ${value}`;
    });

    return () => {
        cleanupBaseUrl();
        cleanupUserLocale();
        cleanupAccessToken();
        constructionProjectClient = null;
    };
}

function installOrderClient(globals: ExternalGlobals) {
    const getOrderServiceUrl = (environment: string) => {
        if (import.meta.env.VITE_LOCAL_API) {
            return 'http://localhost:4000/order-service/local';
        }
        if (import.meta.env.VITE_ORDER_SERVICE_URL) {
            return import.meta.env.VITE_ORDER_SERVICE_URL;
        }
        return getUrls(resolveSflxEnv(environment), import.meta.env.VITE_BRANCH_NAME_ENV).ORDER_SERVICE_URL;
    };

    orderClient = axios.create({
        baseURL: getOrderServiceUrl(globals.environment.value),
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
        },
    });

    const cleanupEnvironment = globals.environment.onUpdate(value => {
        assertNotNull(orderClient);
        orderClient.defaults.baseURL = getOrderServiceUrl(value);
    });

    const cleanupLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(orderClient);
        orderClient.defaults.headers['Accept-Language'] = value;
    });

    return () => {
        cleanupEnvironment();
        cleanupLocale();
        orderClient = null;
    };
}

function installPositionClient(globals: ExternalGlobals) {
    const getPositionServiceUrl = (environment: string) => {
        if (import.meta.env.VITE_LOCAL_API) {
            return 'http://localhost:4000/position-service/local';
        }
        if (import.meta.env.VITE_POSITION_SERVICE_URL) {
            return import.meta.env.VITE_POSITION_SERVICE_URL;
        }
        return getUrls(resolveSflxEnv(environment), import.meta.env.VITE_BRANCH_NAME_ENV).POSITION_SERVICE_URL;
    };

    positionClient = axios.create({
        baseURL: getPositionServiceUrl(globals.environment.value),
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
        },
    });

    const cleanupEnvironment = globals.environment.onUpdate(value => {
        assertNotNull(positionClient);
        positionClient.defaults.baseURL = getPositionServiceUrl(value);
    });

    const cleanupLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(positionClient);
        positionClient.defaults.headers['Accept-Language'] = value;
    });

    return () => {
        cleanupEnvironment();
        cleanupLocale();
        positionClient = null;
    };
}

function installFulfillmentClient(globals: ExternalGlobals) {
    const getFulfillmentServiceUrl = (environment: string) => {
        if (import.meta.env.VITE_LOCAL_API) {
            return 'http://localhost:4000/order-fulfillment-service/local';
        }
        if (import.meta.env.VITE_FULFILLMENT_SERVICE_URL) {
            return import.meta.env.VITE_FULFILLMENT_SERVICE_URL;
        }
        return getUrls(resolveSflxEnv(environment), import.meta.env.VITE_BRANCH_NAME_ENV).FULFILLMENT_SERVICE_URL;
    };

    fulfillmentClient = axios.create({
        baseURL: getFulfillmentServiceUrl(globals.environment.value),
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
        },
    });

    const cleanupEnvironment = globals.environment.onUpdate(value => {
        assertNotNull(fulfillmentClient);
        fulfillmentClient.defaults.baseURL = getFulfillmentServiceUrl(value);
    });

    const cleanupLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(fulfillmentClient);
        fulfillmentClient.defaults.headers['Accept-Language'] = value;
    });

    return () => {
        cleanupEnvironment();
        cleanupLocale();
        fulfillmentClient = null;
    };
}

function installCustomRequestClient(globals: ExternalGlobals) {
    const getCustomRequestUrl = (environment: string) => {
        if (import.meta.env.VITE_LOCAL_API) {
            return 'http://localhost:4000/custom-request-service/local';
        }
        if (import.meta.env.VITE_CUSTOM_REQUEST_SERVICE_URL) {
            return import.meta.env.VITE_CUSTOM_REQUEST_SERVICE_URL;
        }
        return getUrls(resolveSflxEnv(environment), import.meta.env.VITE_BRANCH_NAME_ENV).CUSTOM_REQUEST_SERVICE_URL;
    };

    customRequestClient = axios.create({
        baseURL: getCustomRequestUrl(globals.environment.value),
        headers: {
            'Accept-Language': globals.userLocale.value,
            'X-Client-Version': globals.hostAppVersion,
        },
    });

    const cleanupEnvironment = globals.environment.onUpdate(value => {
        assertNotNull(customRequestClient);
        customRequestClient.defaults.baseURL = getCustomRequestUrl(value);
    });

    const cleanupLocale = globals.userLocale.onUpdate(value => {
        assertNotNull(customRequestClient);
        customRequestClient.defaults.headers['Accept-Language'] = value;
    });

    return () => {
        cleanupEnvironment();
        cleanupLocale();
        customRequestClient = null;
    };
}

export function setupHttpClients(globals: ExternalGlobals) {
    const cleanups = [
        installConstructionProjectClient(globals),
        installCustomRequestClient(globals),
        installMonolithClient(globals),
        installOrderClient(globals),
        installPositionClient(globals),
        installFulfillmentClient(globals),
    ];

    return () => {
        cleanups.forEach(cleanup => cleanup());
    };
}
