import { useCallback, useEffect } from 'react';

/**
 * This hook adds a warning message to the user when they try to leave the page.
 * The warning message is a browser default message.
 */
export function useBeforeUnloadWarning(enabled: boolean) {
    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (enabled) {
                event.preventDefault();
                return '';
            }
        },
        [enabled]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [handleBeforeUnload]);
}
