import { LoadingSpinner } from '@schuettflix/react-components';
import React, { HTMLAttributes } from 'react';
import { cn } from '@/shared/utils/cn.ts';

export type InlineLoadingSpinnerProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export function InlineLoadingSpinner({ className }: InlineLoadingSpinnerProps) {
    return (
        <div className={cn('flex justify-center py-4', className)}>
            <LoadingSpinner />
        </div>
    );
}
