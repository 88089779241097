import { CombinedProductsCard } from './components/CombinedProductsCard.tsx';
import { ProductCard } from './components/ProductCard.tsx';
import { useTranslation } from 'react-i18next';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';
import { TemplateGroup } from '@schuettflix/interfaces';
import { groupTemplateGroupsBySection } from '@/shared/components/ProductSelection/PresetSelection/utils/groupTemplateGroupsBySection.ts';

interface PresetSelectionProps {
    onSelect: (template: PrefillTemplate) => void;
    templateGroups?: TemplateGroup[];
}

export const PresetSelection = ({ onSelect, templateGroups }: PresetSelectionProps) => {
    const { t } = useTranslation();
    const templateGroupsBySection = groupTemplateGroupsBySection(templateGroups ?? []);

    return (
        <section className="flex flex-col">
            <h2 className="font-headline-lg">{`2. ${t('sections.productGroupSelection.title')}`}</h2>
            <div className="mt-6 flex w-full flex-col gap-8">
                {(['TRANSPORT_MATERIAL', 'TRANSPORT', 'MATERIAL', 'SERVICE'] as const).map(type => {
                    return (
                        <div key={type} className="flex flex-col">
                            <div className="pb-2">
                                <span className="text font-copy-xs-strong uppercase">
                                    {
                                        {
                                            TRANSPORT_MATERIAL: `${t('products.richCatalogue.materialHeader')} + ${t('products.richCatalogue.transportHeader')}`,
                                            TRANSPORT: t('products.richCatalogue.transportHeader'),
                                            MATERIAL: t('products.richCatalogue.materialHeader'),
                                            SERVICE: t('products.richCatalogue.serviceHeader'),
                                        }[type]
                                    }
                                </span>
                            </div>
                            {type === 'TRANSPORT_MATERIAL' ? (
                                <div className="grid gap-2">
                                    {(templateGroupsBySection.TRANSPORT_MATERIAL ?? []).map(templateGroup => (
                                        <CombinedProductsCard
                                            key={templateGroup.id}
                                            templateGroup={templateGroup}
                                            onSelect={onSelect}
                                            dataTest={`combineProductCard-${templateGroup.name}`}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="grid grid-cols-2 gap-2">
                                    {(templateGroupsBySection[type] ?? []).map(templateGroup => (
                                        <ProductCard
                                            key={templateGroup.id}
                                            templateGroup={templateGroup}
                                            onSelect={onSelect}
                                            context={type.toLowerCase() as Lowercase<typeof type>}
                                            dataTest={`productCard-${templateGroup.name}`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </section>
    );
};
