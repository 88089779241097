import { z } from 'zod';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';

export const PatchQuoteFulfillmentRequestMetaSchema = z.object({
    quote: z.object({
        amount: z.number().optional(),
        unit: SUPPORTED_UNITS_SCHEMA.optional(),
        serviceDate: z
            .string()
            .transform(date => new Date(date))
            .optional(),
        partnerOrganizationId: z.number().int().positive(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive().optional(),
        purchasePrice: z.number().optional(),
        purchaseTaxClassId: z.string().optional(),
        salesPrice: z.number().optional(),
        salesTaxClassId: z.string().optional(),
    }),
    customerInformation: z.object({
        customerOrganizationId: z.number(),
    }),
    orderProduct: z.object({
        productCategory: PRODUCT_CATEGORY_SCHEMA,
    }),
});

export const PatchQuoteFulfillmentRequestSchema = z.object({
    meta: PatchQuoteFulfillmentRequestMetaSchema,
    payload: z.object({
        name: z.string(),
    }),
});

export const PatchQuoteFulfillmentUrlSchema = z.object({
    id: z.string().uuid(),
});
