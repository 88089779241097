import { createPositionOrder } from './position';
import { useMutation } from '@tanstack/react-query';

export function getCreatePositionOrderQueryKey(positionId: string | null) {
    return ['create-position-order', positionId];
}

export const useCreatePositionOrder = (positionId: string | null) => {
    return useMutation({
        mutationKey: getCreatePositionOrderQueryKey(positionId),
        mutationFn: async () => (positionId ? createPositionOrder(positionId) : null),
    });
};
