import { z } from 'zod';

export const OrganizationTypeSchema = z.enum(['platform', 'client', 'carrier', 'supplier']);

export type OrganizationType = z.infer<typeof OrganizationTypeSchema>;

export const OrganizationTypesSchema = z.array(OrganizationTypeSchema);

export type OrganizationTypes = z.infer<typeof OrganizationTypesSchema>;

// Note: This schema does not contain all fields. Feel free to extend whenever needed.
export const OrganizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    billingAddress: z.object({
        city: z.string(),
        country: z.string(),
        number: z.string(),
        state: z.string(),
        street: z.string(),
        zip: z.string(),
    }),
    marketCode: z.string(),
});
export type Organization = z.infer<typeof OrganizationSchema>;
