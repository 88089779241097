import z from 'zod';
import { BILLING_TYPE_SCHEMA } from './billing-types.ts';
import { ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA } from './role.ts';

export const PaymentSettingSchema = z.object({
    billingMethod: BILLING_TYPE_SCHEMA,
    paymentTermId: z.number().nullable(),
    //TODO: remove nullable when this ticket is done MAR-3072
    billingInterval: z.string().nullable(),
});

export const PaymentSettingsResponseSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    selected: PaymentSettingSchema,
});

export const PaymentSettingsWithOptionsResponseSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    selected: PaymentSettingSchema.omit({ billingInterval: true }),
    options: PaymentSettingSchema.omit({ billingInterval: true }).array(),
});

export const PaymentSettingsWithTransactionalRoleResponseSchema = z.strictObject({
    transactionalRole: ORDER_ORGANIZATION_TRANSACTIONAL_ROLE_SCHEMA,
    ...PaymentSettingSchema.shape,
});
