import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { authTokenFromLocalStorage } from '@/shared/utils/localStorage.ts';
import { z } from 'zod';
import { getMarketsFromToken } from '@/shared/utils/getMarketsFromToken.ts';
import { useQueryClient } from '@tanstack/react-query';

export const MarketCodeSchema = z.enum(['DE', 'AT', 'CZ', 'PL']);

export type MarketCode = z.infer<typeof MarketCodeSchema>;

type MarketContextType = {
    marketCode: MarketCode;
    setMarketCode: (newMarketCode: MarketCode, callback?: () => void) => void;
    getAvailableMarketCodes: () => MarketCode[];
};

export type MarketContextProviderProps = PropsWithChildren & {
    defaultMarketCode?: string;
};

const LOCAL_STORAGE_KEY = 'platform';

// NOTE: This function should only be used in where the context can not be access via `useMarketContext` hook!
// The return value should not be cached to ensure the most updated value is used.
// e.g. When setting a HTTP header this method should be called shortly before the actual request is made.
export function getMarketCodeFromLocalStorage() {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
}

export function setMarketCodeToLocalStorage(marketCode: MarketCode): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, marketCode as string);
}

const DEFAULT_MARKET_CODE: MarketCode = 'DE';

const MarketContext = createContext<MarketContextType | undefined>(undefined);

function getAvailableMarketCodes() {
    return getMarketsFromToken(authTokenFromLocalStorage())
        .map(m => {
            const result = MarketCodeSchema.safeParse(m);
            return result.success ? result.data : undefined;
        })
        .filter(m => m !== undefined);
}

function getInitialMarketCode(input: MarketContextProviderProps['defaultMarketCode']) {
    const inputResult = MarketCodeSchema.safeParse(input);
    if (inputResult.success) {
        return inputResult.data;
    }

    const storageResult = MarketCodeSchema.safeParse(getMarketCodeFromLocalStorage());
    let marketCode: MarketCode;
    if (storageResult.success) {
        marketCode = storageResult.data;
    } else {
        const fallbackMarketCode = getAvailableMarketCodes().shift();
        marketCode = fallbackMarketCode || DEFAULT_MARKET_CODE;
    }

    return marketCode;
}

export function MarketContextProvider({ children, defaultMarketCode }: MarketContextProviderProps) {
    const [marketCode, setMarketCodeState] = useState<MarketCode>(getInitialMarketCode(defaultMarketCode));
    const queryClient = useQueryClient();

    const setMarketCode = useCallback<MarketContextType['setMarketCode']>(
        (newMarketCode, callback) => {
            if (newMarketCode !== marketCode) {
                queryClient.removeQueries();
                setMarketCodeState(newMarketCode);
                setMarketCodeToLocalStorage(newMarketCode);
                // NOTE: BEWARE that this callback can NOT rely on the market code retrieved from the context!!!
                // `setMarketCodeState` is an asynchronous update whereas the callback is called synchronous.
                // The reason it is implemented this way at the moment is because of:
                // 1) Callbacks inside on `useState` need to PURE. Therefore, no side effects, like a callback, are allowed. See also: https://react.dev/reference/react/useState#setstate
                // 2) The only purpose  for the callback is to navigate to a new route in `MarketSelector`, which trigger a new render cycle any ways.
                if (callback) {
                    callback();
                }
            }
        },
        [marketCode, queryClient]
    );

    // NOTE: This ensures that the local storage market code is always in sync with the context!
    setMarketCodeToLocalStorage(marketCode);

    return (
        <MarketContext.Provider value={{ marketCode, setMarketCode, getAvailableMarketCodes }}>
            {children}
        </MarketContext.Provider>
    );
}

export function useMarketContext() {
    const context = useContext(MarketContext);
    if (!context) {
        throw new Error('MarketContextProvider must be provided!');
    }
    return context;
}
