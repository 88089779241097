import { getMonolithClient } from '@/clients/httpClients.ts';

interface GrainSize {
    min: number;
    max: number;
    unit: string;
}

interface ImageUrl {
    original: string;
    small: string;
    medium: string;
    large: string;
    thumbnail: string;
    logo: string;
}

interface Image {
    fileName: string;
    uuid: string;
    url: ImageUrl;
}

interface ProductCategory {
    id: string;
    label: string;
    image: Image;
}

export interface SellerProduct {
    id: string;
    masterProductId: string;
    factoryId: number;
    grainSize: GrainSize;
    name: string;
    price: string;
    minimumGuaranteedAmount: number;
    productCategories: ProductCategory[];
    attributeValues: any[];
    partnerSku: string | null;
    customName: string;
    available: boolean;
    isBlockedOnSpotMarket: boolean;
    tags: any[];
    availableOnMarket: boolean;
    productDefinitionId: string;
}

export function getSellerProductsByFactory(factoryId: number) {
    return getMonolithClient()
        .get<{ items: SellerProduct[] }>(`/v1/factories/${factoryId}/seller-products`)
        .then(res => res.data);
}

interface CreateProductRequest {
    available: boolean;
    price: string;
    masterProductId: string;
    minimumGuaranteedAmount: number;
    grainSize?: {
        min: number;
        max: number;
        unit: string;
    };
    isBlockedOnSpotMarket: boolean;
    attributeValues: string[];
}

export function createSellerProduct({ factoryId, body }: { factoryId: number; body: CreateProductRequest }) {
    return getMonolithClient()
        .post(`/v2/factories/${factoryId}/seller-products`, body)
        .then(res => res.data);
}

interface PatchProductRequest {
    available: boolean;
    isBlockedOnSpotMarket: boolean;
    minimumGuaranteedAmount: number;
    price: number;
}

export function patchProduct({ productId, body }: { productId: string; body: PatchProductRequest }) {
    return getMonolithClient()
        .patch(`/v1/seller-products/${productId}`, body)
        .then(res => res.data);
}

export interface SupplierProductPreset {
    id: string;
    name: string;
    category: string;
    masterProductId: string;
    attributeValues: string[];
}

export function getSellerProductPresets(): Promise<{ presets: SupplierProductPreset[] }> {
    return getMonolithClient()
        .get<{ presets: SupplierProductPreset[] }>(`/v1/seller-product-presets`)
        .then(res => res.data);
}
