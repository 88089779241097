import { getFulfillmentClient } from '@/clients/httpClients.ts';
import {
    GetFulfillmentProductGroupsResponseSchema,
    GetFulfillmentProductsResponseSchema,
    NestedFulfillmentProductGroupResponseSchema,
    GetOrderStatusResponseSchema,
    NestedFulfillmentProductResponseItemSchema,
    PatchFulfillmentProductConfigurationResponseSchema,
    PostFulfillmentProductResponseSchema,
    GetOrderStatusResponse,
    ListResponseBody,
    PatchFulfillmentProductConfigurationRequest,
    PatchFulfillmentProductConfigurationResponse,
    PatchFulfillmentProductConfigurationUrl,
    PostFulfillmentProductRequest,
    PostFulfillmentProductResponse,
} from '@schuettflix/interfaces';
import z from 'zod';

export const getOrderStatus = async (orderId: string) => {
    return await getFulfillmentClient()
        .get<GetOrderStatusResponse>(`/v1/orders/${orderId}/status`)
        .then(response => GetOrderStatusResponseSchema.parse(response.data));
};

export const getFulfillmentProductsByOrderId = async (orderId: string) => {
    return await getFulfillmentClient()
        .get<ListResponseBody<z.input<typeof NestedFulfillmentProductResponseItemSchema>>>(
            `/v1/product-fulfillment/${orderId}`
        )
        .then(response => GetFulfillmentProductsResponseSchema.parse(response.data.items));
};

export const patchFulfillmentProductConfiguration = async ({
    fulfillmentProductId,
    fulfillmentProductConfiguration,
}: {
    fulfillmentProductId: PatchFulfillmentProductConfigurationUrl['fulfillmentProductId'];
    fulfillmentProductConfiguration: PatchFulfillmentProductConfigurationRequest;
}) => {
    return await getFulfillmentClient()
        .patch<PatchFulfillmentProductConfigurationResponse>(
            `/v1/product-fulfillment/${fulfillmentProductId}`,
            fulfillmentProductConfiguration
        )
        .then(response => PatchFulfillmentProductConfigurationResponseSchema.parse(response.data));
};

export const getFulfillmentProductGroupsByOrderId = async (orderId: string) => {
    return await getFulfillmentClient()
        .get<ListResponseBody<z.input<typeof NestedFulfillmentProductGroupResponseSchema>>>(
            `/v1/product-group-fulfillment/order/${orderId}`
        )
        .then(response => GetFulfillmentProductGroupsResponseSchema.parse(response.data.items));
};

export const postFulfillmentProduct = async (fulfillmentProduct: PostFulfillmentProductRequest) => {
    return await getFulfillmentClient()
        .post<PostFulfillmentProductResponse>(`/v1/product-fulfillment`, fulfillmentProduct)
        .then(response => PostFulfillmentProductResponseSchema.parse(response.data));
};

export const deleteFulfillmentProduct = async ({ fulfillmentProductId }: { fulfillmentProductId: string }) => {
    await getFulfillmentClient().delete(`/v1/product-fulfillment/${fulfillmentProductId}`);
};
