import { useSuspenseQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { getPositionQuoteByPositionProductId } from '../clients/CustomRequestPositionClient';

export function getPositionQuoteQueryKey(positionProductId: string | null) {
    return ['positionQuote', positionProductId];
}

export const usePositionQuote = (positionProductId: string | null) =>
    useSuspenseQuery({
        queryKey: getPositionQuoteQueryKey(positionProductId),
        queryFn: async () => {
            if (!positionProductId) {
                return null;
            }
            try {
                return await getPositionQuoteByPositionProductId({ positionProductId });
            } catch (error) {
                // since the query might return a 404, we need to prevent the query to throw an error for that case
                if (isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        refetchOnWindowFocus: false,
    });
