import z from 'zod';

export const ConstructionProjectStatusSchema = z.enum(['ACTIVE', 'INACTIVE', 'ARCHIVED']);

export const GetConstructionProjectResponseSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    description: z.string().nullish(),
    color: z.string().nullish(),
    status: ConstructionProjectStatusSchema,
    costCenter: z.string().nullish(),
    // neglected field `teamMembers`
    country: z.string().nullish(),
    state: z.string().nullish(),
    city: z.string().nullish(),
    zip: z.string().nullish(),
    street: z.string().nullish(),
    addressNumber: z.string().nullish(),
    projectCode: z.string().nullish(),
    isFavorite: z.boolean(),
    // neglected field `geoCoordinate`
    validFrom: z.string(),
    validTo: z.string(),
    responsibleUser: z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: z.string(),
        images: z.any().nullish(),
    }),
    // neglected field `responsibleEmployee`
    organizationId: z.number(),
    // neglected field `orderConfirmationMails`
});

const GetConstructionProjectsListEntitySchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    description: z.string().nullish(),
    responsibleUser: z.object({
        id: z.number().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        imageUrl: z.string().nullish(),
    }),
    country: z.string().nullish(),
    state: z.string().nullish(),
    city: z.string().nullish(),
    zip: z.string().nullish(),
    street: z.string().nullish(),
    addressNumber: z.string().nullish(),
    projectCode: z.string().nullish(),
    status: ConstructionProjectStatusSchema,
});

export const GetConstructionProjectListResponseSchema = z.object({
    constructionProjects: z.array(GetConstructionProjectsListEntitySchema),
});

export type GetConstructionProjectResponse = z.infer<typeof GetConstructionProjectResponseSchema>;
export type ConstructionProjectStatus = z.infer<typeof ConstructionProjectStatusSchema>;
export type GetConstructionProjectsListResponse = z.infer<typeof GetConstructionProjectListResponseSchema>;
export type GetConstructionProjectsListEntity = z.infer<typeof GetConstructionProjectsListEntitySchema>;
export type FilterOptionsConstructionProject = {
    statuses?: ConstructionProjectStatus[];
};
