import { CustomRequestProductFormProvider } from '@/CustomRequestProductChannel/components/CustomRequestProductFormProvider.tsx';
import {
    OrderCustomRequestProductManager,
    OrderCustomRequestProductManagerProps,
} from '@/CustomRequestProductChannel/modules/order/OrderCustomRequestProductManager.tsx';

export type OrderCustomRequestProductProps = OrderCustomRequestProductManagerProps;

export function OrderCustomRequestProduct(props: OrderCustomRequestProductProps) {
    return (
        <CustomRequestProductFormProvider>
            <OrderCustomRequestProductManager {...props} />
        </CustomRequestProductFormProvider>
    );
}
