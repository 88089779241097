import { OrderWizard } from '@/modules/order/components/OrderWizard/OrderWizard.tsx';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from '@/shared/components/DocumentTitle.tsx';
import { FullscreenLoadingSuspenseBoundary } from '@/shared/components/FullscreenLoadingSuspenseBoundary.tsx';
import { BeforeUnloadContextProvider } from '@/shared/context/BeforeUnloadContext.tsx';

export function OrderPage() {
    const { orderId } = useParams<{ orderId: string }>();
    const { t } = useTranslation();

    if (!orderId) {
        return;
    }

    return (
        <>
            <DocumentTitle title={t('page.title')} />
            <FullscreenLoadingSuspenseBoundary>
                <BeforeUnloadContextProvider defaultEnabled>
                    <OrderWizard orderId={orderId} />
                </BeforeUnloadContextProvider>
            </FullscreenLoadingSuspenseBoundary>
        </>
    );
}
