import { isAxiosError } from 'axios';
import { getProductQuoteByProductId } from '../clients/CustomRequestClient';
import { useSuspenseQuery } from '@tanstack/react-query';

export function getProductQuoteQueryKey(productId: string) {
    return ['custom-request', 'quote', productId];
}

export const useProductQuote = (productId: string) =>
    useSuspenseQuery({
        queryKey: getProductQuoteQueryKey(productId),
        queryFn: async () => {
            try {
                return await getProductQuoteByProductId({ productId: productId });
            } catch (error) {
                // since the query might return a 404, we need to prevent the query to throw an error for that case
                if (isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
    });
