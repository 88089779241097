import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { NestedPositionProductConfigurationSchema } from './nested-position-product-configuration.ts';

export const PostPositionProductUrlSchema = z.object({
    positionId: z.string().uuid(),
    productGroupId: z.string().uuid(),
});

export const PostPositionProductRequestSchema = z.object({
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
});

export const PostPositionProductResponseSchema = z.object({
    ...PostPositionProductRequestSchema.shape,
    id: z.string().uuid(),
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    positionProductConfiguration: NestedPositionProductConfigurationSchema,
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
