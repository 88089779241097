import { PresetSelection } from '@/shared/components/ProductSelection/PresetSelection/PresetSelection';
import { usePositionTemplateGroups } from '@/clients/template/useTemplate.ts';
import { useTranslation } from 'react-i18next';
import {
    useDeletePositionProductGroup,
    usePositionProductGroups,
    useUpdatePositionProductGroup,
} from '@/clients/position/usePositionProductGroup';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { ProductSelection } from '@/shared/components/ProductSelection/ProductSelection';
import {
    ProductSelectionSummary,
    ProductSelectionSummaryRow,
} from '@/shared/components/ProductSelection/ProductSelectionSummary';
import { ProductGroups, ProductGroupsProps } from '@/shared/components/ProductSelection/ProductGroups';
import { usePosition } from '@/clients/position/usePosition.ts';
import { useCallback, useState } from 'react';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { useIsMutating } from '@tanstack/react-query';
import { useCreatePositionProduct, useDeletePositionProduct } from '@/clients/position/usePositionProduct';
import { PositionCustomRequestProduct } from '@/CustomRequestProductChannel/modules/position/PositionCustomRequestProduct';
import { usePositionQuote } from '@/CustomRequestProductChannel/hooks/usePositionQuote.ts';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';
import { useCreatePositionProductGroupsByTemplate } from '@/clients/position/useCreatePositionProductGroupsByTemplate';
import { InlineLoadingSuspenseBoundary } from '@/shared/components/InlineLoadingSuspenseBoundary.tsx';

export interface PositionProductSelectionProps extends PositionSection {
    positionId: string;
}

export const PositionProductSelection = ({
    positionId,
    isSectionOpen,
    onEditCurrentSection,
    onMoveToNextSection,
}: PositionProductSelectionProps) => {
    const { t } = useTranslation();

    const templateGroups = usePositionTemplateGroups();
    const positionProductGroups = usePositionProductGroups(positionId);
    const { mutateAsync: mutateDeletePositionProductGroup } = useDeletePositionProductGroup(positionId);
    const { mutateAsync: mutateDeletePositionProduct } = useDeletePositionProduct(positionId);
    const createPositionProduct = useCreatePositionProduct(positionId);
    const { data: position } = usePosition(positionId);
    const { isEditing } = useEditPositionState();
    const createPositionProductGroupByTemplate = useCreatePositionProductGroupsByTemplate(positionId);

    const orderProductGroups: ProductGroupsProps['orderProductGroups'] = positionProductGroups.data
        ? positionProductGroups.data.map(group => ({
              ...group,
              orderProducts: group.products?.map(product => ({
                  ...product,
                  orderProductGroupId: product.positionProductGroupId,
                  templateId: product.templateId,
                  positionProductId: null,
                  productConfiguration: product.positionProductConfiguration,
              })),
          }))
        : [];

    const addProductToProductGroup = async (product: Product, productGroupId: string) => {
        const orderProducts = orderProductGroups[0].orderProducts;
        const highestExistingIndex = orderProducts?.map(product => product.index).sort((a, b) => b - a)[0] ?? 0;
        await createPositionProduct.mutateAsync({
            productGroupId,
            product: {
                type: product.type,
                index: highestExistingIndex + 1,
                category: product.category,
            },
        });
    };

    const { mutate: mutateUpdatePositionProductGroup } = useUpdatePositionProductGroup(positionId);
    const handleProductGroupNameChange = useCallback(
        (updatedProductGroupName: string | undefined, productGroupId: string) => {
            mutateUpdatePositionProductGroup({
                productGroupId,
                productGroup: {
                    name: updatedProductGroupName ?? '',
                },
            });
        },
        [mutateUpdatePositionProductGroup]
    );

    const handleDeletePositionProductGroup = async (productGroupId: string) => {
        await mutateDeletePositionProductGroup({ productGroupId });
        onEditCurrentSection();
    };

    const [completedProducts, setCompletedProducts] = useState<Set<string>>(() => new Set([]));

    const disableNextButton = useIsMutating({ mutationKey: ['order-product-group', 'product-quote'] }) > 0;

    const customRequestProductRenderer = useCallback<ProductGroupsProps['productRender']>(
        props => {
            const handleProductCompleteChange = (productId: string, isComplete: boolean) => {
                setCompletedProducts(prevState => {
                    if (isComplete) {
                        prevState.add(productId);
                    } else {
                        prevState.delete(productId);
                    }
                    return prevState;
                });
            };

            return (
                <PositionCustomRequestProduct
                    {...props}
                    positionId={positionId}
                    onCompleteChange={handleProductCompleteChange}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [positionId, orderProductGroups]
    );

    if (positionProductGroups.error) {
        return <div className="text-red-800">{positionProductGroups.error.message}</div>;
    }

    return (
        <InlineLoadingSuspenseBoundary className="p-[95px]">
            <ProductSelection
                isLoading={
                    positionProductGroups.isLoading ||
                    createPositionProductGroupByTemplate.isPending ||
                    createPositionProduct.isPending
                }
                isOpen={isSectionOpen}
                hasProductGroups={!!positionProductGroups.data?.length}
                summarySlot={
                    <ProductSelectionSummary
                        orderProductGroups={orderProductGroups}
                        onEdit={onEditCurrentSection}
                        onDelete={handleDeletePositionProductGroup}
                        disableDelete={isEditing}
                        rowRenderer={({ productId }) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const { data: positionQuote } = usePositionQuote(productId);
                            return <ProductSelectionSummaryRow quote={constructProductQuote({ positionQuote })} />;
                        }}
                    />
                }
                presetSelectionSlot={
                    <PresetSelection
                        templateGroups={templateGroups?.data}
                        onSelect={createPositionProductGroupByTemplate.mutateAsync}
                    />
                }
                productGroupsSlot={
                    position?.customerInfo ? (
                        <ProductGroups
                            onNextSection={onMoveToNextSection}
                            orderProductGroups={orderProductGroups}
                            onDeleteProductGroupById={productGroupId =>
                                mutateDeletePositionProductGroup({ productGroupId })
                            }
                            onDeleteProductById={(productId, productGroupId) =>
                                mutateDeletePositionProduct({ productId, productGroupId })
                            }
                            onAddProductToProductGroup={addProductToProductGroup}
                            onProductGroupNameChange={handleProductGroupNameChange}
                            completedProducts={completedProducts}
                            disableNextButton={disableNextButton}
                            nextButtonLabel={t('product.productTemplates.toPositionSettingsBtn')}
                            isNextButtonSecondary={isEditing}
                            productRender={customRequestProductRenderer}
                            disableDeleteAllProducts={isEditing}
                        />
                    ) : null
                }
            />
        </InlineLoadingSuspenseBoundary>
    );
};
