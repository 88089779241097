import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { GetPositionQuoteResponseSchema } from './get-position-quote.ts';
import { floatingPointPrecisionSchema } from './position-quote-status.ts';

export const PostPositionQuoteRequestSchema = z.object({
    positionId: z.string().uuid(),
    positionProductId: z.string().uuid(),
    name: z.string(),
    quota: floatingPointPrecisionSchema,
    unit: SUPPORTED_UNITS_SCHEMA,
    partnerOrganizationId: z.number().positive(),
    projectOwnerOrganizationId: z.number().positive(),
    platformOrganizationId: z.number().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string().max(3),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
});

export const PostPositionQuoteResponseSchema = GetPositionQuoteResponseSchema;
