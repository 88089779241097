import React, { PropsWithChildren } from 'react';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import {
    customRequestProductSchemaDefaults,
    useCustomRequestProductSchema,
} from '@/CustomRequestProductChannel/hooks/useCustomRequestProductSchema.ts';
import { FormProvider } from 'react-hook-form';
import { z, ZodSchema } from 'zod';

type CustomRequestProductFormProviderProps = PropsWithChildren & {
    intersectionSchema?: ZodSchema;
};

export function CustomRequestProductFormProvider({
    children,
    intersectionSchema,
}: CustomRequestProductFormProviderProps) {
    const customRequestProductSchema = useCustomRequestProductSchema();
    const mergedSchema = intersectionSchema
        ? z.intersection(customRequestProductSchema, intersectionSchema)
        : customRequestProductSchema;

    const methods = useZodForm({
        schema: mergedSchema,
        defaultValues: customRequestProductSchemaDefaults,
        mode: 'onChange',
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
}
