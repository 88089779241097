import React, { useEffect } from 'react';
import { useOrganization } from '@/clients/organization/useOrganization.ts';
import { SelectOrganization } from '@/shared/components/SelectOrganization.tsx';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { ReactHookFormDevTool } from '@/shared/components/ReactHookFormDevTool.tsx';
import {
    GetProductGroupListResponse,
    NestedOrderProductResponse,
    PRODUCT_CATEGORY_LABEL,
    PRODUCT_NAME_PLACEHOLDER_LABEL,
    SERVICE_PRODUCT_NAME_PLACEHOLDER,
    SERVICE_PRODUCT_TYPES_PLACEHOLDER,
    SUPPORTED_UNIT_LABEL,
    CURRENCY_DECIMALS,
    NestedOrderProductConfiguration,
} from '@schuettflix/interfaces';
import { DatePicker, NumberField } from '@schuettflix/planum-react';
import { parseDate } from '@internationalized/date';
import { Combobox, TextField } from '@schuettflix/react-components';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { DeleteIcon } from '@schuettflix/icons-react';
import { FormattedActiveTaxClasses } from '@/clients/tax-classes/useTaxClasses.ts';
import {
    EnrichedHookFormError,
    enrichHookFormErrorForFulfillmentSectionErrors,
} from '@/CustomRequestProductChannel/utils/enrichHookFormErrorForFulfillmentSectionErrors.ts';
import { getProductCategoryIcon } from '@/CustomRequestProductChannel/utils/getProductCategoryIcon.ts';
import { useSupportedUnitNamesByProductType } from '@/CustomRequestProductChannel/hooks/useSupportedUnitNamesByProductType.ts';
import { cn } from '@/shared/utils/cn.ts';
import {
    CustomRequestProductSchema,
    useCustomRequestProductSchema,
} from '@/CustomRequestProductChannel/hooks/useCustomRequestProductSchema.ts';
import { usePresetFormValues } from '@/CustomRequestProductChannel/hooks/usePresetFormValues.ts';
import { CustomRequestProductHints } from '@/CustomRequestProductChannel/components/CustomRequestProductHints.tsx';
import { EmitterSubscriber } from '@/shared/hooks/useEmitter';

export type CustomRequestProductConfiguration = Omit<NestedOrderProductConfiguration, 'orderProductId'> | null;
export type CustomRequestProduct = Pick<
    NestedOrderProductResponse,
    'id' | 'category' | 'type' | 'positionProductId' | 'index'
> & { productConfiguration: CustomRequestProductConfiguration };
export type CustomRequestProductForm = CustomRequestProductSchema;
export type FormErrors = FieldErrors<CustomRequestProductForm>;
type FieldErrorKey = keyof CustomRequestProductForm;

export type CustomRequestProductQuote = Pick<
    NonNullable<NestedOrderProductResponse['quote']>,
    | 'partnerOrganizationId'
    | 'unit'
    | 'purchasePrice'
    | 'salesPrice'
    | 'purchaseTaxClassId'
    | 'currencyCode'
    | 'salesTaxClassId'
> & {
    id?: string;
    amount?: number;
    serviceDate?: NonNullable<NestedOrderProductResponse['quote']>['serviceDate'];
    name: string;
};

type FormFieldSettings = Partial<
    Record<
        keyof CustomRequestProductQuote,
        {
            // to overwrite the label of the field
            label?: string;
            // to make the field optional in the validation
            isOptional?: boolean;
            // to hide the field in the UI
            isHidden?: boolean;
            isInvalid?: boolean;
        }
    >
>;

export interface BaseCustomRequestProductProps {
    // To intersect the schema with the form schema (extend schema)
    intersectionSchema?: z.ZodObject<{
        salesPrice: z.ZodEffects<z.ZodString, string, string>;
    }>;
    // Leading values are always overwriting the form values
    leadingValues?: Partial<Omit<CustomRequestProductForm, 'currencyCode'>>;
    product: CustomRequestProduct;
    quote?: CustomRequestProductQuote;
    customerId: number;
    showValidation: boolean; // shared
    showProductPriceAddedHint?: boolean; // shared
    showProductPriceNotAddedHint?: boolean; // shared
    showZeroPriceProductConfiguration?: boolean; // shared
    enableDelete?: boolean; // shared
    disabled?: boolean; // shared
    disabledFields?: Partial<Record<keyof Omit<CustomRequestProductForm, 'currencyCode'>, boolean>>;
    taxInfo?: FormattedActiveTaxClasses;
    formFieldSettings?: FormFieldSettings;
    onFormChange?: (
        form: (
            | { values: Partial<CustomRequestProductForm>; isValid: false }
            | { values: CustomRequestProductForm; isValid: true }
        ) & {
            productId: CustomRequestProduct['id'];
        }
    ) => void; // shared
    onBeforeFormChange?: (values: Partial<CustomRequestProductForm>) => void; // shared
    onDeleteProduct?: (productId: string) => void; // shared
    onUpdateProductConfiguration?: (values: Partial<CustomRequestProductConfiguration>) => void; //shared
    templateId?: GetProductGroupListResponse['templateId']; // shared
    restrictFutureServiceDate?: boolean; // shared
    onErrorsChange?: (errors: EnrichedHookFormError[]) => void;
    onIsDirtyChange?: (isDirty: boolean) => void;
    resetEmitter?: EmitterSubscriber;
}

export function CustomRequestProduct({
    intersectionSchema,
    leadingValues,
    product,
    quote,
    formFieldSettings,
    templateId,
    customerId,
    taxInfo,
    showValidation,
    showProductPriceAddedHint = false,
    showProductPriceNotAddedHint = false,
    showZeroPriceProductConfiguration = true,
    enableDelete,
    disabled = false,
    disabledFields = {},
    onFormChange,
    onBeforeFormChange,
    onDeleteProduct,
    onErrorsChange,
    restrictFutureServiceDate = false,
    onUpdateProductConfiguration,
    onIsDirtyChange,
    resetEmitter,
}: BaseCustomRequestProductProps) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(customerId);
    const { getCurrencySymbol } = useFormatters();
    const { data: supportedUnitNames } = useSupportedUnitNamesByProductType(product.type);

    const customRequestProductSchema = useCustomRequestProductSchema();
    const customRequestFormSchema = intersectionSchema
        ? z.intersection(customRequestProductSchema, intersectionSchema)
        : customRequestProductSchema;

    const {
        handleSubmit,
        control,
        formState,
        register,
        reset,
        trigger,
        setValue,
        getValues,
        watch,
        setFocus,
        getFieldState,
    } = useFormContext<CustomRequestProductSchema>();

    const { presetValues } = usePresetFormValues({
        leadingValues,
        product,
        quote,
        formFieldSettings,
        templateId,
        customerId,
        taxInfo,
    });

    useEffect(() => {
        if (!presetValues) {
            return;
        }

        // NOTE: using set timeout to delay the reset, since other components are rendered at the same time which produces the following warning
        // Warning: Cannot update a component (`DevTool`) while rendering a different component (`CustomRequestProduct`)
        const timeout = setTimeout(() => {
            reset(presetValues);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetValues]);

    const { errors, isDirty } = formState;

    useEffect(() => {
        onIsDirtyChange?.(isDirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => resetEmitter?.subscribe(() => reset(presetValues)), [resetEmitter, presetValues]);

    const formValues = getValues();
    const { data: partnerOrganization } = useOrganization(formValues.partnerOrganizationId);

    useEffect(() => {
        const subscription = watch(values => {
            onBeforeFormChange?.(values);
            try {
                customRequestFormSchema.parse(values);
                if (values.serviceDate && validateServiceDateConsideringFutureRestriction(values.serviceDate)) {
                    throw new Error('Service date is in the future');
                }
                void handleSubmit(
                    // fires when the form is really valid without errors
                    values => {
                        onFormChange?.({ values, isValid: true, productId: product.id });
                    },
                    // fires when the form is invalid and has errors (and shows validation to the user)
                    () => {
                        onFormChange?.({ values, isValid: false, productId: product.id });
                    }
                )();
                // In case the schema parsing failed we know that the current form is invalid
                // the parse() throws an error
            } catch {
                onFormChange?.({ values, isValid: false, productId: product.id });
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch, onFormChange]);

    useEffect(() => {
        const values = getValues();
        onBeforeFormChange?.(values);
        try {
            if (values.serviceDate && validateServiceDateConsideringFutureRestriction(values.serviceDate)) {
                throw new Error('Service date is in the future');
            }
            customRequestFormSchema.parse(values);
            onFormChange?.({ values, isValid: true, productId: product.id });
        } catch {
            onFormChange?.({ values, isValid: false, productId: product.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateServiceDateConsideringFutureRestriction = (date: string) => {
        return restrictFutureServiceDate && date && new Date(date) > new Date();
    };

    const getFormError = (fieldName: FieldErrorKey) => {
        return errors[fieldName];
    };

    const getFormErrorMessage = (fieldName: FieldErrorKey) => {
        const formError = getFormError(fieldName);
        return formError?.message;
    };

    const isNegativePurchasePrice = Number(formValues.purchasePrice) < 0;
    const isNegativeSalesPrice = Number(formValues.salesPrice) < 0;
    const isZeroPurchasePrice = Number(formValues.purchasePrice) === 0;
    const isZeroSalesPrice = Number(formValues.salesPrice) === 0;

    useEffect(() => {
        onErrorsChange?.(enrichHookFormErrorForFulfillmentSectionErrors({ errors, setFocus }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        errors.salesPrice,
        errors.purchasePrice,
        errors.amount,
        errors.unit,
        errors.productName,
        errors.partnerOrganizationId,
        errors.serviceDate,
        errors.purchaseTaxClassId,
        errors.salesTaxClassId,
    ]);

    useEffect(() => {
        if (showValidation) {
            trigger().catch(e => console.error(e));
        }
    }, [showValidation, trigger]);

    useEffect(() => {
        if (!leadingValues) return;

        Object.entries(leadingValues).map(([key, value]) => {
            if (!value) return;
            setValue(key as keyof typeof leadingValues, value, { shouldDirty: true, shouldValidate: true });
        });

        // somehow `leadingValue` is losing its reference, so we cant add it to the dependency array, needs some deeper investigation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        leadingValues?.amount,
        leadingValues?.unit,
        leadingValues?.serviceDate,
        leadingValues?.partnerOrganizationId,
        leadingValues?.purchaseTaxClassId,
        leadingValues?.salesTaxClassId,
        leadingValues?.productName,
        setValue,
    ]);

    useEffect(() => {
        if (getFieldState('salesPrice').isDirty) {
            trigger('salesPrice').catch(e => console.error(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFieldSettings?.salesPrice?.isInvalid]);

    const PriceFieldSuffix = () => {
        const unit = getValues('unit');

        return (
            <NumberField.Suffix>{`${getCurrencySymbol(organization?.market.currencyCode)}${
                unit ? ' / ' + t(SUPPORTED_UNIT_LABEL[unit]) : ''
            }`}</NumberField.Suffix>
        );
    };

    return (
        <>
            <form className="focus-within:bg-hovered group px-4 py-8">
                <div className="flex flex-col gap-4">
                    <p className="font-copy-sm-strong uppercase [counter-increment:productCounter] before:content-[counter(productCounter)'.\0020']">
                        {t(PRODUCT_CATEGORY_LABEL[product.category])}
                    </p>
                    <div className="flex md:grid md:grid-cols-[2fr_3fr]">
                        <TextField
                            {...register('productName')}
                            variant="sm"
                            className="flex-grow"
                            placeholder={
                                product.type in SERVICE_PRODUCT_NAME_PLACEHOLDER
                                    ? t(
                                          SERVICE_PRODUCT_NAME_PLACEHOLDER[
                                              product.type as SERVICE_PRODUCT_TYPES_PLACEHOLDER
                                          ]
                                      )
                                    : t(PRODUCT_NAME_PLACEHOLDER_LABEL[product.category])
                            }
                            isCritical={!!getFormError('productName')}
                            disabled={disabled || disabledFields.productName}
                            errorMessage={getFormErrorMessage('productName')}
                            leadingIcon={getProductCategoryIcon(product.category)}
                            data-test={`order-product-name-${product.category}`}
                        />
                        <div className="flex flex-shrink-0 items-start justify-end pl-4 pt-4">
                            <DeleteIcon
                                monochrome
                                className={!enableDelete ? 'cursor-not-allowed' : 'cursor-pointer'}
                                disabled={!enableDelete}
                                onClick={() => onDeleteProduct?.(product.id)}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row [&>*]:flex-1">
                        <div>
                            <div className="grid grid-cols-9 gap-0">
                                <Controller
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            {...field}
                                            value={Number(value)}
                                            stature="sm"
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : Number(value));
                                            }}
                                            isInvalid={!!getFormError('amount')}
                                            errorMessage={getFormErrorMessage('amount')}
                                            isDisabled={disabledFields.amount || disabled}
                                            className={cn(
                                                'relative col-span-5 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('amount') }
                                            )}
                                            formatOptions={{
                                                maximumFractionDigits: 3,
                                                minimumFractionDigits: 0,
                                            }}
                                            placeholder={
                                                formFieldSettings?.amount?.label ?? t('product.quote.quantity')
                                            }
                                            data-test={`order-product-amount-${product.category}`}
                                        />
                                    )}
                                    name={'amount'}
                                    control={control}
                                />
                                <Controller
                                    name="unit"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className={`col-span-4 cy-order-product-unit-${product.category}`}
                                            {...field}
                                            options={(supportedUnitNames ?? []).map(unit => {
                                                return {
                                                    value: unit,
                                                    label: t(SUPPORTED_UNIT_LABEL[unit]),
                                                };
                                            })}
                                            placeholder={t('product.quote.unit')}
                                            disabled={disabledFields.unit || disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('unit')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-1 gap-0">
                                <Controller
                                    name={'partnerOrganizationId'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <SelectOrganization
                                            {...field}
                                            selectedOrganizationId={field.value}
                                            placeholder={t('product.addPosition.partner.label')}
                                            filters={{
                                                isActive: true,
                                                isBlocked: false,
                                                status: ['approved'],
                                            }}
                                            critical={!!fieldState.error}
                                            helperText={t('product.addPosition.errorMessage.pleaseEnterValue')}
                                            disabled={disabledFields.partnerOrganizationId || disabled}
                                            size="sm"
                                            excludeMatchingIds={{ id: customerId }}
                                            dataTest={`order-product-partner-organization-${product.category}`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-5 gap-0">
                                <Controller
                                    name="purchasePrice"
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            {...field}
                                            className={cn(
                                                'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('purchasePrice') }
                                            )}
                                            value={Number(value)}
                                            stature="sm"
                                            trailingSlot={<PriceFieldSuffix />}
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : String(value));
                                            }}
                                            isDisabled={disabledFields.purchasePrice || disabled}
                                            isInvalid={!!getFormError('purchasePrice')}
                                            errorMessage={getFormErrorMessage('purchasePrice')}
                                            placeholder={t('product.quote.purchasePrice')}
                                            formatOptions={{
                                                maximumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                                minimumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                            }}
                                            data-test={`order-product-purchase-price-${product.category}`}
                                        />
                                    )}
                                />

                                <Controller
                                    name="purchaseTaxClassId"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className="col-span-2"
                                            {...field}
                                            options={taxInfo?.taxClassOptions || []}
                                            placeholder={t('product.quote.tax')}
                                            disabled={disabledFields.purchaseTaxClassId || disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('purchaseTaxClassId')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-5 gap-0">
                                <Controller
                                    name="salesPrice"
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <NumberField
                                            className={cn(
                                                'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                                { 'z-10': !!getFormError('salesPrice') }
                                            )}
                                            {...field}
                                            value={Number(value)}
                                            stature={'sm'}
                                            placeholder={t('product.quote.salesPrice')}
                                            formatOptions={{
                                                maximumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                                minimumFractionDigits:
                                                    CURRENCY_DECIMALS[organization!.market.currencyCode],
                                            }}
                                            onChange={value => {
                                                onChange(Number.isNaN(value) ? undefined : String(value));
                                            }}
                                            isInvalid={!!getFormError('salesPrice')}
                                            errorMessage={getFormErrorMessage('salesPrice')}
                                            isDisabled={disabledFields.salesPrice || disabled}
                                            trailingSlot={<PriceFieldSuffix />}
                                            data-test={`order-product-sales-price-${product.category}`}
                                        />
                                    )}
                                />

                                <Controller
                                    name="salesTaxClassId"
                                    control={control}
                                    render={({ field: { ref: _, ...field } }) => (
                                        <Combobox
                                            className="col-span-2"
                                            {...field}
                                            options={taxInfo?.taxClassOptions || []}
                                            placeholder={t('product.quote.tax')}
                                            disabled={disabledFields.salesTaxClassId || disabled}
                                            variant="sm"
                                            buttonClassName="rounded-l-none"
                                            errorMessage={getFormErrorMessage('salesTaxClassId')}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {!formFieldSettings?.serviceDate?.isHidden && (
                            <div
                                className="[&_.planum-datePicker-module-trigger]:min-w-full"
                                data-test={`date-picker-${product.category}`}
                            >
                                <Controller
                                    name="serviceDate"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <DatePicker
                                            value={value ? parseDate(value) : undefined}
                                            onChange={date => {
                                                onChange(date?.toString());
                                            }}
                                            label={t('product.quote.serviceDate')}
                                            stature="sm"
                                            isDisabled={disabled || disabledFields.serviceDate}
                                            data-test={`order-product-service-date-${product.category}`}
                                        />
                                    )}
                                />
                                <span className="text-critical font-copy-sm mt-2">
                                    {getFormErrorMessage('serviceDate')}
                                </span>
                                {validateServiceDateConsideringFutureRestriction(formValues.serviceDate) ? (
                                    <span className="text-critical font-copy-sm mt-2">
                                        {t('product.addPosition.errorMessage.serviceDateInFuture')}
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
                <CustomRequestProductHints
                    isNegativePurchasePrice={isNegativePurchasePrice}
                    isNegativeSalesPrice={isNegativeSalesPrice}
                    isZeroPurchasePrice={isZeroPurchasePrice}
                    isZeroSalesPrice={isZeroSalesPrice}
                    productConfiguration={product.productConfiguration}
                    partnerOrganizationName={partnerOrganization?.name}
                    orderingOrganizationName={organization?.name}
                    onUpdateProductConfiguration={onUpdateProductConfiguration}
                    showProductPriceNotAddedHint={showProductPriceNotAddedHint}
                    showProductPriceAddedHint={showProductPriceAddedHint}
                    showZeroPriceProductConfiguration={showZeroPriceProductConfiguration}
                />
            </form>
            <ReactHookFormDevTool control={control} />
        </>
    );
}
