import z from 'zod';

export const PositionEditCommitActionsSchema = z.strictObject({
    deleted: z.array(
        z.strictObject({
            originalPositionProductId: z.string().uuid(),
        })
    ),
    updated: z.array(
        z.strictObject({
            originalPositionProductId: z.string().uuid(),
            temporaryPositionProductId: z.string().uuid(),
        })
    ),
    created: z.array(
        z.strictObject({
            temporaryPositionProductId: z.string().uuid(),
        })
    ),
});

export type PositionEditCommitActions = z.infer<typeof PositionEditCommitActionsSchema>;

export const PostPaymentSettingsPositionEditCommitRequestSchema = z.strictObject({
    originalPositionId: z.string().uuid(),
    temporaryPositionId: z.string().uuid(),
    actions: PositionEditCommitActionsSchema,
});

export type PostPaymentSettingsPositionEditCommitRequest = z.infer<
    typeof PostPaymentSettingsPositionEditCommitRequestSchema
>;
