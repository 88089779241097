import { z } from 'zod';
import { NestedOrderProductResponseSchema } from '../order-product/NestedProductResponseEntity.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from './product-group-type.ts';

export const GetProductGroupListQueryParams = z.strictObject({
    orderId: z.string().uuid().optional(),
});

/**
 * validation schema for response payload
 */
export const GetProductGroupListResponseSchema = z.strictObject({
    id: z.string(),
    index: z.number(),
    name: z.string().nullable(),
    orderId: z.string(),
    templateId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    orderProducts: NestedOrderProductResponseSchema.array().optional(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
});

export type GetProductGroupListResponse = z.input<typeof GetProductGroupListResponseSchema>;
