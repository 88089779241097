import { z } from 'zod';
import { AbstractGetQuoteListResponseSchema } from '../quote/get-quote-list.ts';

export const GetQuoteListQuerySchema = z.strictObject({
    orderId: z.optional(z.string().uuid()),
    orderProductId: z.optional(z.string().uuid()),
    orderProductGroupId: z.optional(z.string().uuid()),
});

/**
 * validation schema for specific response payload
 */
const CustomRequestGetListResponsePayload = z.strictObject({
    payload: z.strictObject({
        // add custom fields of the fulfillment channel here
        name: z.string(),
    }),
});

/**
 * validation schema for whole (merged) response payload
 */
export const GetQuoteListResponseSchema = AbstractGetQuoteListResponseSchema.merge(CustomRequestGetListResponsePayload);
