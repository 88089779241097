import { Select } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { MarketCode, useMarketContext } from '@/shared/context/MarketContext.tsx';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type MarketSelectorProps = {
    disabled?: boolean;
};

export const MarketSelector = ({ disabled = false }: MarketSelectorProps) => {
    const { marketCode, getAvailableMarketCodes, setMarketCode } = useMarketContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleChange = useCallback(
        function handleChangeCallback(input: MarketCode | null) {
            if (input !== null) {
                setMarketCode(input, () => navigate('/'));
            }
        },
        [setMarketCode, navigate]
    );

    // NOTE: DO NOT memoize these options to ensure that on each render the current market codes are used.
    const options = getAvailableMarketCodes().map(marketCode => ({ value: marketCode, label: marketCode }));

    if (options.length <= 1) {
        return null;
    }

    return (
        <div>
            <h3 className="font-copy-md-strong mb-2">{t('sections.platformSelection.description')}</h3>
            <Select<MarketCode>
                value={marketCode}
                onChange={handleChange}
                options={options}
                label={t('sections.platformSelection.platformLabel')}
                disabled={disabled}
            />
        </div>
    );
};
