import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrder } from '@/clients/order/useOrder.ts';
import { ClientInformationFormOrder } from '@/modules/order/components/ClientInformationFormOrder.tsx';
import { OrderSummary } from '@/modules/order/components/OrderSummary/OrderSummary.tsx';
import { OrderProductSelection } from '@/modules/order/components/ProductSelection/OrderProductSelection.tsx';
import { PageHeader } from '@/shared/components/PageHeader.tsx';
import { InlineLoadingSuspenseBoundary } from '@/shared/components/InlineLoadingSuspenseBoundary.tsx';
import { SectionPreview } from '@/shared/components/SectionPreview.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { getOrderSummaryQueryKey } from '@/clients/order/useOrderSummary.ts';

export interface OrderWizardProps {
    orderId: string;
}

export const OrderWizard: React.FC<OrderWizardProps> = ({ orderId }) => {
    const { t } = useTranslation();
    const { data: order } = useOrder(orderId);
    const [currentSectionNumber, setCurrentSectionNumber] = useState(order?.status === 'SUBMITTED' ? 3 : 1);
    // NOTE: These callback function remove the order summary data from the cache whenever a user clicks the edit icon.
    // This solution does not take into account that if the user makes no changes that no new data is needed.
    // But the current implementation of the wizard would require a bigger refactoring in order to optimize this solution.
    const queryClient = useQueryClient();

    const handleClientInformationEdit = useCallback(() => {
        queryClient.removeQueries({ queryKey: getOrderSummaryQueryKey(orderId) });
        setCurrentSectionNumber(1);
    }, [queryClient, orderId]);

    const handleProductSelectionEdit = useCallback(() => {
        queryClient.removeQueries({ queryKey: getOrderSummaryQueryKey(orderId) });
        setCurrentSectionNumber(2);
    }, [queryClient, orderId]);

    if (!order) {
        return null;
    }

    return (
        <section className="bg-light-gray-200 min-h-screen">
            <PageHeader title={t('page.title')} />
            <main className="pt-18 w-full">
                <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-4">
                    <ClientInformationFormOrder
                        isOpen={currentSectionNumber === 1}
                        orderId={orderId}
                        onComplete={() => setCurrentSectionNumber(2)}
                        onEdit={handleClientInformationEdit}
                    />

                    {currentSectionNumber <= 2 && (
                        <div className="border-t-divider my-6 w-full max-w-[720px] border-t" />
                    )}

                    <InlineLoadingSuspenseBoundary className="p-[95px]">
                        <OrderProductSelection
                            isOpen={currentSectionNumber === 2}
                            onComplete={() => setCurrentSectionNumber(3)}
                            onEdit={handleProductSelectionEdit}
                            onDelete={() => setCurrentSectionNumber(2)}
                            orderId={orderId}
                            orderPositionId={order.positionId}
                        />
                    </InlineLoadingSuspenseBoundary>

                    {currentSectionNumber >= 2 && (
                        <div className="border-t-divider my-6 w-full max-w-[720px] border-t" />
                    )}

                    <div className="mb-18 flex w-full justify-center">
                        {currentSectionNumber === 3 ? (
                            <InlineLoadingSuspenseBoundary>
                                <OrderSummary
                                    orderId={order.id}
                                    orderPositionId={order.positionId}
                                    orderHumanReadableId={order.humanReadableId}
                                    orderStatus={order.status}
                                />
                            </InlineLoadingSuspenseBoundary>
                        ) : (
                            <SectionPreview
                                description={t('sections.summary.preview.description')}
                                headline={`3. ${t('sections.summary.title')}`}
                            />
                        )}
                    </div>
                </div>
            </main>
        </section>
    );
};
