import { z } from 'zod';
import { POSITION_STATUS_SCHEMA, ProjectNumberSchema, ProjectPositionNumberSchema } from './shared';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const GetPositionUrlSchema = z.object({
    positionId: z.string().uuid(),
});

const CustomerInfoSchema = z.strictObject({
    id: z.string().uuid(),
    positionId: z.string().uuid(),
    constructionProjectId: z.string().uuid(),
    organizationId: z.number(),
    userId: z.number(),
    manualCostCenter: z.string().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});

const PositionProductSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    unit: SUPPORTED_UNITS_SCHEMA.optional().nullable(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
    templateId: z.string().uuid().nullable(),
    positionProductGroupId: z.string().uuid(),
    quota: z.number(),
    availableQuantity: z.number(),
    reservedQuantity: z.number().nonnegative(),
    fulfilledQuantity: z.number().nonnegative(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});

const PositionProductGroupSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    positionId: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    products: z.array(PositionProductSchema),
});

export const GetPositionResponseSchema = z.strictObject({
    id: z.string().uuid(),
    projectNumber: ProjectNumberSchema,
    positionNumber: ProjectPositionNumberSchema,
    marketCode: z.string(),
    validFrom: z.coerce.date().nullable(),
    validTo: z.coerce.date().nullable(),
    note: z.string().nullable(),
    status: POSITION_STATUS_SCHEMA,
    active: z.boolean(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
    orderCount: z.number(),
    customerInfo: CustomerInfoSchema,
    positionProductGroups: z.array(PositionProductGroupSchema),
});

export type GetPositionResponse = z.infer<typeof GetPositionResponseSchema>;
