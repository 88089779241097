import { TextField } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';

type ProductSelectionIndividualLineItemListingProps = {
    onIsDirtyChange?: (isDirty: boolean) => void;
};

export const ProductSelectionIndividualLineItemListing = ({
    onIsDirtyChange: setIsDirty,
}: ProductSelectionIndividualLineItemListingProps) => {
    const { t } = useTranslation();
    return (
        <div className="mt-8 rounded bg-white p-4">
            <p className="font-copy-sm-strong uppercase">
                {t('sections.productGroupSelection.individualLineItemListing.sectionTitle')}
            </p>
            <TextField
                placeholder="dummy input"
                className="mt-4 max-w-[200px]"
                variant="sm"
                onChange={e => setIsDirty?.(!!e.target.value)}
            />
        </div>
    );
};
