import React from 'react';
import { EditIcon } from '@schuettflix/icons-react';
import { InlineLoadingSuspenseBoundary } from '@/shared/components/InlineLoadingSuspenseBoundary.tsx';

interface SectionSummarySharedProps {
    title: string;
    children?: React.ReactNode;
}

interface SectionSummaryNotEditableProps extends SectionSummarySharedProps {
    editable?: never;
    onEditClick?: never;
}

interface SectionSummaryEditableProps extends SectionSummarySharedProps {
    editable: true;
    onEditClick: () => void;
}

type SectionSummaryProps = SectionSummaryNotEditableProps | SectionSummaryEditableProps;

export const SectionSummary = ({ title, editable, onEditClick, children }: SectionSummaryProps) => {
    return (
        <div className="bg-surface w-full max-w-[720px] rounded p-4">
            <div className="mb-2 flex justify-between">
                <div className="flex items-center">
                    <h2 className="font-copy-md text" aria-disabled>
                        {title}
                    </h2>
                </div>

                {editable ? (
                    <span>
                        <EditIcon onClick={onEditClick} className="cursor-pointer" />
                    </span>
                ) : null}
            </div>
            <InlineLoadingSuspenseBoundary>
                <div className="text-subdued font-copy-sm">{children}</div>
            </InlineLoadingSuspenseBoundary>
        </div>
    );
};
