import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import React, { useState } from 'react';
import { patchProduct, SellerProduct } from '@/clients/sellerProducts/SellerProduct.ts';
import { Button, TextField } from '@schuettflix/react-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { invalidateSellerProductsQuery } from '@/clients/sellerProducts/useSellerProduct.ts';

const EditForm = z.object({
    pricePerTon: z.number().positive(),
    stockInTons: z.number().positive(),
    available: z.boolean(),
    isBlockedOnSpotMarket: z.boolean(),
});

export function EditFactoryProductForm({ product, factoryId }: { product: SellerProduct; factoryId: number }) {
    const {
        handleSubmit,
        register,
        formState: { isValid, isDirty },
        reset,
    } = useZodForm({
        schema: EditForm,
        defaultValues: {
            pricePerTon: parseFloat(product.price),
            stockInTons: product.minimumGuaranteedAmount,
            available: product.available,
            isBlockedOnSpotMarket: product.isBlockedOnSpotMarket,
        },
        mode: 'onChange',
    });

    const [formBodyError, setFormBodyError] = useState<string | null>(null);

    const queryClient = useQueryClient();
    const editSellerProductMutation = useMutation({
        mutationKey: ['seller-products', 'patch', product.id],
        mutationFn: patchProduct,
        onError: (error: unknown) => {
            const validationError = z
                .object({
                    response: z.object({
                        data: z.array(
                            z.object({
                                property_path: z.string(),
                                message: z.string(),
                            })
                        ),
                    }),
                })
                .safeParse(error);
            if (validationError.success) {
                for (const error of validationError.data.response.data) {
                    switch (error.property_path) {
                        case 'body': {
                            setFormBodyError(error.message);
                            break;
                        }
                    }
                }
            }
        },
    });

    return (
        <>
            <form
                className="flex items-center justify-between gap-1"
                onSubmit={handleSubmit(async formData => {
                    setFormBodyError(null);
                    await editSellerProductMutation.mutateAsync({
                        productId: product.id,
                        body: {
                            available: formData.available,
                            price: formData.pricePerTon,
                            minimumGuaranteedAmount: formData.stockInTons,
                            isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                        },
                    });
                    await invalidateSellerProductsQuery(queryClient, factoryId);
                    reset({
                        available: formData.available,
                        pricePerTon: formData.pricePerTon,
                        stockInTons: formData.stockInTons,
                        isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                    });
                })}
            >
                <div className="flex shrink-0 flex-grow basis-[150px] justify-between gap-4">
                    <div>
                        <label>Active</label>
                        <input type="checkbox" {...register('available')} />
                    </div>
                    <div>
                        <label>Only visible for SFLX</label>
                        <input type="checkbox" {...register('isBlockedOnSpotMarket')} />
                    </div>
                </div>
                <TextField
                    className="shrink-0 flex-grow basis-[150px]"
                    {...register('pricePerTon', { valueAsNumber: true })}
                    label="Price per Ton"
                    step={0.1}
                    type="number"
                />
                <TextField
                    className="shrink-0 flex-grow basis-[150px]"
                    {...register('stockInTons', { valueAsNumber: true })}
                    label="Stock in Tons"
                    step={0.1}
                    type="number"
                />
                <Button
                    label={'Update'}
                    type="submit"
                    disabled={!isValid || !isDirty || editSellerProductMutation.isPending}
                />
            </form>
            {formBodyError ? <p className="text-critical">{formBodyError}</p> : null}
        </>
    );
}
