import { z } from 'zod';
import { NestedOrderProductConfigurationSchema } from './NestedProductResponseEntity.ts';

export const PatchOrderProductConfigurationUrlSchema = z.object({
    orderProductId: z.string().uuid(),
});

export const PatchOrderProductConfigurationRequestSchema = z.object({
    showZeroPurchasePrice: z.boolean().optional(),
    showZeroSalesPrice: z.boolean().optional(),
});

export const PatchOrderProductConfigurationResponseSchema = NestedOrderProductConfigurationSchema;

export type PatchOrderProductConfigurationRequest = z.infer<typeof PatchOrderProductConfigurationRequestSchema>;
export type PatchOrderProductConfigurationUrl = z.infer<typeof PatchOrderProductConfigurationUrlSchema>;
export type PatchOrderProductConfigurationResponse = z.infer<typeof PatchOrderProductConfigurationResponseSchema>;
