import { z } from 'zod';
import { GetProductQuoteResponseSchema } from './get-quote.ts';
import { ACTIVE_QUOTE_STATUS_SCHEMA } from '../quote/quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';

export const PostProductQuoteRequestSchema = z.object({
    meta: z.object({
        id: z.string().uuid(),
        status: ACTIVE_QUOTE_STATUS_SCHEMA,
        createdAt: z.coerce.date(),
        updatedAt: z.coerce.date(),
        // order information
        orderId: z.string().uuid(),
        orderProductGroupId: z.string().uuid(),
        orderProductId: z.string().uuid(),
        // product information
        amount: z.number(),
        unit: SUPPORTED_UNITS_SCHEMA,
        serviceDate: z.coerce.date(),
        // payment information
        partnerOrganizationId: z.number().int().positive(),
        orderingOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        purchasePrice: z.number(),
        purchaseTaxClassId: z.string(),
        salesPrice: z.number(),
        salesTaxClassId: z.string(),
        currencyCode: z.string(),
    }),
    // will be overwritten by custom fulfillment channel payload
    payload: z.any(),
});

/**
 * The response body schema of the POST /product-quote endpoint
 */
export const PostProductQuoteResponseSchema = GetProductQuoteResponseSchema;
