import { z } from 'zod';
import { PRODUCT_CATEGORY_SCHEMA } from '../order-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { FULFILLMENT_STATUS_SCHEMA, CHANNEL_STATUS_SCHEMA } from './get-fulfillment-status.ts';
import { FulfillmentProductConfigurationSchema } from './patch-product-configuration.ts';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type.ts';

export const GetProductsUrlSchema = z.strictObject({
    orderId: z.string().uuid(),
});

export const FULFILLMENT_PRODUCT_SOURCE_SCHEMA = z.enum(['ORDER', 'FULFILLMENT']);

export const NestedFulfillmentProductResponseItemSchema = z.object({
    id: z.string().uuid(),
    productGroupId: z.string().uuid(),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    status: FULFILLMENT_STATUS_SCHEMA,
    completedAt: z.coerce.date().nullable(),
    completedBy: z.number().nullable(),
    channelStatus: CHANNEL_STATUS_SCHEMA,
    source: FULFILLMENT_PRODUCT_SOURCE_SCHEMA,
    index: z.number(),
    fulfillmentProductConfiguration: FulfillmentProductConfigurationSchema.nullable(),
});

export const NestedFulfillmentProductGroupResponseSchema = z.object({
    id: z.string().uuid(),
    name: z.string().nullish(),
    orderId: z.string().uuid(),
    status: FULFILLMENT_STATUS_SCHEMA,
    groupType: PRODUCT_GROUP_TYPE_SCHEMA,
    products: z.array(NestedFulfillmentProductResponseItemSchema),
});

export const GetFulfillmentProductsResponseSchema = z.array(NestedFulfillmentProductResponseItemSchema);
export const GetFulfillmentProductGroupsResponseSchema = z.array(NestedFulfillmentProductGroupResponseSchema);

export type GetFulfillmentProductsResponse = z.infer<typeof GetFulfillmentProductsResponseSchema>;
export type FULFILLMENT_PRODUCT_SOURCE = z.infer<typeof FULFILLMENT_PRODUCT_SOURCE_SCHEMA>;
export type NestedFulfillmentProductGroupResponse = z.infer<typeof NestedFulfillmentProductGroupResponseSchema>;
